$red-soft: #FD6060;
$dark-red: #E65656;
$very-dark-red: #D24C4C;
$amber: #ffb700;
$yellow: #fad64b;
$green: #7bba05;
$placeholder-color: rgba(50, 60, 71, 0.45);
$input-text-color: rgba(50, 60, 71, 0.9);
$blue: #3180e2;
$dark-yellow: #DAA520;
$orange: #e67e22;
$dark: #233539;
