.nps-widget {
  .nps-scores {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 5px 0;
    overflow-x: auto;

    .nps-button {
      border: 1px solid #ccc;
      cursor: pointer;
      background-color: #f5f5f5;
      border-radius: 5px;
      flex: 1 1 auto;
      padding: 10px;
      margin: 3px;
      text-align: center;
      font-size: 14px;
      width: 40px;
      height: 40px;

      &:hover, &.selected {
        background-color: #3180e2;
        color: #ffffff;
         border: 0;
      }

      @media (max-width: 767px) {
        padding: 5px;
        margin: 2px;
        font-size: 12px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .nps-labels {
    display: flex;
    justify-content: space-between;

    span {
      flex: 1;
      text-align: center;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 12px;
      }

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .nps-reason {
    display: flex;
    justify-content: center;
    gap: 20px;

    textarea {
      margin: 5px 0 20px 0;
      padding: 10px;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  .nps-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}
