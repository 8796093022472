.progressbar-container {
  margin-top: 5px;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.progressbar {
  width: 70%;
  margin-left: -10px;
  margin-top: 5px;
}

.progressbar li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
}

.progressbar li:before {
  content: '';
  width: 14px;
  height: 14px;
  border: 1px solid #bebebe;
  display: block;
  margin: 0 0 0 8px;
  border-radius: 50%;
  line-height: 10px;
  background: #edeeef;
  text-align: center;
  font-weight: bold;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #edeeef;
  top: 8px;
  left: -75%;
  z-index: -1;
}

.progressbar li.active + li:after {
  background: #a5e89c;
}

.progressbar li:first-child:after {
  content: none;
}

.inner-circle {
  position: absolute;
  background: #c4cacc;
  border-radius: 50%;
  height: 8px !important;
  width: 8px !important;
  left: 12px;
  bottom: 4px;
}

.step-1,
.step-2,
.step-3,
.step-4,
.step-5 {
  background: #307fe2 !important;
}

.step-active {
  background: #5fc850 !important;
}

.current-step:before {
  background: #c8dffb !important;
  border: 1px solid #c8dffb !important;
}

.step-passed:before {
  background: #c9edc4 !important;
  border: 1px solid #c9edc4 !important;
}

.status-striped {
  background: #fbfbfb;
}

.status-count > div:nth-child(odd) {
  background: #f4f7f9;
}

.status-count > div:nth-child(even) {
  background: #eef3f7;
}

.custom-mt {
  margin-top: -20px !important;
}

.custom-mb-10 {
  margin-bottom: 15px;
}

.icon-run-progress {
  width: 20px !important;
}

.run-progress .list-item.dot-progress {
  height: 80px !important;
}

@media (max-width: 768px) {
  .custom-sm-mt {
    margin-top: 15px !important;
  }

  .custom-mb-10 {
    margin-bottom: 0px !important;
  }
}


@media (max-width: 576px) {
  .progressbar-container {
    display: none;
  }

  .run-progress .custom-mt {
    margin: 0;
  }
}
