.white-popup {
  .wrap_info {
    position: relative;
  }

  .mfp-close {
    &:active {
      top: -25px;
    }

    right: -50px;
    top: -25px;
  }
}

#watch-demo-popup {
  .wrap_info {
    padding: 15px !important;
  }

  iframe {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

#billing-popup {
  max-width: 70%;
  position: relative !important;
  padding: 10px;
  background-color: #f5f5ef;
}

#billing-pdf-embed {
  width: 100%;
  height: 800px;
}

#billing-popup {
  .mfp-close {
    font-size: 40px;
  }
}
