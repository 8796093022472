.nps-survey-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .nps-widget {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;

    @media (max-width: 767px) {
      max-width: 100%;
      margin: 0 10px;
    }
  }
}

#nps-survey-popup {
  padding: 50px 30px;

  .fa-times {
    position: absolute;
    top: -45px;
    right: -25px;
    opacity: 0.8;
    font-size: 18px;
    padding: 10px;

    &:hover {
      opacity: 1;
    }
  }

  .nps-widget {
    margin-top: 30px;
  }

  h2 {
    border: 0;
    padding: 0 !important;
    font-size: 16px;
  }
}
