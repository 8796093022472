@import "run-form";
@import "Participants";
@import "breakpoints";
@import "dropdown";
@import "run-feedback";
@import "typography";
@import "buttons";
@import "run/acticity-status-radio";
@import "variables";

.text-red-soft {
  color: $red-soft !important;
}

.text-dark-red {
  color: $dark-red !important;
}

.text-very-dark-red {
  color: $very-dark-red !important;
}

.text-amber {
  color: $amber !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-green {
  color: $green !important;
}

.text-black {
  color: #202222 !important;
}

.contact-us-textarea {
  font-size: 12px;
  color: $input-text-color;
  letter-spacing: -0.4px;
  margin-bottom: 0px !important;
}

.contact-us-textarea p {
  position: relative;
  bottom: 5px;
}

.circle-progress-align canvas {
  vertical-align: bottom;
}

.wrap_link.body .btn.btn-default {
  background: #f1f1f1;
}

.zindex-0 {
  z-index: 0 !important;
}

$color-error: #fc5a5a;
$btn-danger-color: #e74f4f;

.width-250 {
  width: 250px;
}

.mr-15 {
  margin-right: 15px;
}

.width-533 {
  width: 533px;
}

.cursor-pointer {
  cursor: pointer;
}

.po-pr-0 {
  padding-right: 0 !important;
}

.wrapper_main.project-alert {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.has-error input,
.has-error textarea,
.has-error tags,
.has-error select,
.select2-selection.select2-selection--single.select2-has-error {
  border: 1px solid $color-error !important;
}


.text-error {
  color: $color-error;
}

.wrap_img_user.open .dropdown-menu {
  display: block;
}

.input-group {
  position: relative;

  .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px;
  }

  .billing-page-currency {
    position: absolute;
    width: 56px;
    height: 100%;
    border-radius: 3px 0 0 3px;
    background: rgba(226, 233, 239, 0.5);
    left: 0;
    z-index: 999;
    color: #808fa3;
    font-family: Muli-Bold;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: -0.4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input.billing_currency_input {
    padding-left: 69px;
  }
}

.vertical-space-md {
  margin-top: 25px;
}

.text-required::after {
  content: ' *';
  color: red;
}

.po-text, .chosen-container {
  color: #757c84;
  font-family: Muli-SemiBold;
  font-size: 14px;
}

.po-text-bold {
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
}

.po-text-dark {
  color: #323c47 !important;
}

.po-input {
  color: $input-text-color;
  font-size: 12px;
  font-family: Muli-SemiBold;
  letter-spacing: -0.4px;
}

.text-white {
  color: #ffffff !important;
}

.qs-done-btn {
  margin-left: 70%;
  margin-top: 30px;
  max-width: 125px;
}

.wid-auto {
  width: auto !important;
}

.tagify__dropdown.extra-properties {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  transform: scale(.75);
  margin-right: 5px;
  border-radius: 2px;
  transition: .12s ease-out;
}

.tagify tag > div {
  color: #333 !important;
}

.line-matching-participants {
  box-shadow: none !important;
  padding: 0 100px;
  height: 0 !important;
}

.list-assignment-bg {
  width: 100%;
  background: transparent !important;
}

.cc-expiry-slash {
  text-align: center;
  padding-top: 3px;
  font-size: 30px;
}

.dropdown-menu.show, .inline {
  display: block !important;
}

.select2-container .select2-selection--single {
  height: 40px !important;
  border: 1px solid #e2e9ef !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../images/select_arrow.png) no-repeat;
  background-position: bottom 50% right 15px;
}

.select2-selection__rendered {
  margin-top: 5px !important;
}

.select2-selection__arrow {
  display: none;
}

a {
  text-decoration: none !important;
}

.participant-actions .user_block:before {
  content: none !important;
}

.run-name {
  width: 75%;
}

.credit-cancel {
  background: none !important;
  padding: 0 !important;
}

.education-inp .radio-toolbar label {
  padding-left: 20px !important;
}

.education-inp label {
  margin: 8px 16px !important;
}

.hr-line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e2e9ef;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.subs-complete {
  color: #ffffff !important;
}

.ca-popup {
  max-width: 1013px !important;
}

.current-attribute {
  max-height: 500px;
  overflow-y: auto;
  margin: 1rem !important;
}

.current-attribute .list-item {
  min-height: 50px;
  height: auto;
  padding: 10px !important;
}

@media (max-width: $mediumScreenMaxWidth) {
  .current-attribute {
    margin: 0 !important;
  }

  .current-attribute .list-item {
    height: auto;
  }

  .current-attribute .list-item {
    height: auto;
    align-content: center;
  }

  .current-attribute .list-item > div {
    margin: 0 !important;
  }
}

.message-ids,
.filter-ids,
.bonus-ids {
  max-height: 250px !important;
  overflow-y: auto !important;
}

.clear-h3-margin h3 {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav {
    height: auto !important;
    background-color: #307fe2 !important;
    left: auto !important;
    right: 0;
  }

  .navbar-default .navbar-nav li a {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .navbar-default .navbar-nav li a.active {
    border-bottom: 1px solid rgba(255, 255, 255, 0.85) !important;
    padding-bottom: 0 !important;
  }

  .user_block .wrap_img_user .dropdown-menu li a {
    color: #323c47 !important;
  }

  .cost-formula-pad {
    padding-left: 40px !important;
  }

  .metrics.activity .left .wrap_input_text {
    flex-direction: column;
  }

  #card-popup .cvc-field {
    margin-left: 0px !important;
  }

  #card-popup .month-year-input {
    flex: 0 0 25%;
    max-width: 25%;
  }

  #card-popup .cc-slash {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

.wrap_projects .feedback-item {
  padding: 10px 20px 10px;
  display: flex;
  align-items: center;
  font-family: Muli-SemiBold;
  color: #323c47;
  font-size: 14px;
  box-shadow: 0 1px 0 #e9eff4;
  min-height: 60px;
  height: auto;
}

.feedback-list {
  border-radius: 0 !important;
}

.pull-right {
  float: right !important;
}

.mw-none {
  max-width: none !important;
}

@media (max-width: 320px) {
  #run-details input.form-control, #run-details select {
    padding-left: 10px !important;
  }

  .wrap_link.flex-row {
    flex-direction: column !important;
  }
}

.run-platform {
  max-width: 100% !important;
}

@media (max-width: 767px) {
  .wrap_item .mx-5 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .feedback.copy-link-btn {
    margin-left: 0 !important;
  }
  .copy-completion-link {
    margin-bottom: 5px !important;
  }
}

@media (max-width: 500px) {
  .filter.white-popup h2 {
    font-size: 15px;
  }
  .ml-180 {
    margin-left: 10px;
  }
}

.mr-t {
  margin-top: 10%;
}

.select2-results {
  font-family: Muli-SemiBold !important;
  font-size: 14px !important;

  ul {
    li:first-child:empty {
      padding: 0;
    }
  }

}

.select2-selection__rendered {
  font-family: Muli-SemiBold !important;
  font-size: 14px !important;
}

.run-completion-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.run-mt-1 {
  margin-top: 10px !important;
}

.run-mt-3 {
  margin-top: 30px !important;
}

.text-orange {
  color: #e67e22;
}

.text-yellow {
  color: #ffd800;
}

.translate-y-35 {
  transform: translate(-50%, -35%) !important;
}

@media (max-width: 630px) {
  .bonus-header {
    height: auto !important;
    display: block !important;
    padding-top: 35px !important;
    padding-bottom: 10px !important;
  }
}

@media (max-width: 404px) {
  .message-header {
    display: block !important;
  }

}

@media (max-width: 767px) {
  .set-attribute-h2 {
    display: none;
  }

  .qs-scroll {
    max-height: 265px;
    overflow-y: scroll;
    margin-top: 0px !important;
  }

  .qs-reset-btn,
  .qs-btns-1 {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .set-attribute-h3 {
    display: none;
  }

  .qs-btns-2 {
    display: none !important;
  }
}

.wrapper_link a.integrations_edit {
  background: url("../images/integrations_edit.svg") no-repeat 10px center;
}

.project-edit {
  width: 100%;

  input {
    height: 45px;
    font-size: 20px;
    width: 90%;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid #e2e9ef;
    letter-spacing: -0.86px;
  }
}

@media (max-width: 736px) {
  #card-popup.top-visible {
    margin-top: 120px !important;
  }
}

.po-link,
.link-visited a, .link-visited a:visited {
  color: #307fe2;
}

.no-border {
  border: none !important;
}

.tagify__dropdown {
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 4px 4px;
  border: 1px solid #e2e9ef !important;
  background-color: #ffffff;
  padding: 15px;
}

.tagify__dropdown .tagify__dropdown__item {
  color: $input-text-color;
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: -0.4px;
  cursor: pointer;
}

.tagify__input {
  min-width: 175px !important;
}

.ca-string-input input {
  width: 100% !important;
}

.text-red {
  color: red;
}

.po-text-danger {
  color: #e74f4f;
}

.text-dark-blue {
  color: #3180e2;
}

.border-red {
  border: 1px solid #fc5a5a;
}

.btn-grey {
  background-color: grey !important;
}

.po-link-color {
  color: #307fe2
}

.ml-25x {
  margin-left: 25%;
}

.text-panel-grey {
  color: $input-text-color;
}

.review-panel, .eligible-hits {
  background-color: lightyellow;
  padding: 30px;
}

.review-panel-item, .eligible-hits-item {
  background-color: beige;
  margin: 20px 0px;
  padding: 25px;
}

.eligible-hits-item {
  padding: 20px;
}

.review-panel-item a, .eligible-hits-item a {
  color: #1177fb;
  border: 1px solid #1177fb;
  padding: 5px 15px;
  border-radius: 4px;
}

.bg-none {
  background: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .qs-example-link,
  .copy_code {
    margin-top: 5px;
    margin-left: 0px !important;
  }

  .copy-completion-link .copy_code {
    margin-top: 0;
    margin-left: 5px !important;
  }

  .custom-mw {
    max-width: 120px !important;
  }
}

.po-text-error {
  color: #dc3545 !important;
  font-family: Muli-SemiBold;
  font-size: 14px;
}

.pilot-close {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;;
}

.font-size-12 {
  font-size: 12px !important;
}

.po-demographic-checked input[type="checkbox"]:checked ~ * {
  background-color: #5cc74d !important;
}


.brd-r-1 {
  border-right: 1px solid black;
}

.balance-table {
  width: 100%;
  text-align: center;

  label {
    font-size: 25px;
    letter-spacing: -1.03px;
  }

  h2 {
    font-size: 14px;
    letter-spacing: -0.4px;
  }
}

.transition-0 {
  transition: 0s !important;
}

.qs-reset-btn {
  margin-left: 105px !important;
}

.eligible-participants-icon {
  background: rgba(226, 233, 239, 0.5) url("../images/participants.svg") no-repeat center center !important;
}

.total-cost-icon {
  background: rgba(226, 233, 239, 0.5) url("../images/total.svg") no-repeat center center !important;
}

.edu-item {
  width: 50%;
  padding: 4px 0px;
}

.success-tick {
  background: url("../images/link_done.svg") no-repeat left center;
  padding-left: 20px;
}

.success-tick-filled {
  background: url("../images/p100.svg") no-repeat;
  background-position-y: 5px;
}

.tooltip {
  font-family: Muli-SemiBold !important;
}

.chosen-container {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  color: $input-text-color;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  padding: 3px 15px;
}

.chosen-container-active.chosen-with-drop .chosen-single, .chosen-container-single .chosen-single {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  transition: none !important;
}

.chosen-search {
  display: none;
}

.chosen-container-single .chosen-single div b {
  background: url(../images/select_arrow.png) no-repeat center !important;
}

.user-research-types .tagify tag {
  transition: none;
}

@media(max-width: 767px) {
  .duplicate-btn {
    margin-top: 20px !important;
  }
}

.custom-mb {
  margin-bottom: -18px !important;
  margin-top: 5px;
}

.completion-pane-bg {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.completion-pane-bg .wrap_left {
  width: 55% !important;
}

.completion-pane-bg .wrap_right {
  width: 45% !important;
}

.completion-pane-bg .wrap_left {
  margin-right: 5px !important;
}

.icon-customize {
  z-index: 999;
  position: absolute;
  border-radius: 3px 0 0 3px !important;
  width: 41px;
  height: 45px;
}

.metrics .icon-activity, .icon-clock-circle,
.metrics .icon-bax, .icon-money {
  left: 0;
}

.participants-icon {
  border-radius: 3px 0 0 3px !important;
  width: 41px !important;
  height: 45px !important;
}

.customize-line {
  height: inherit !important;
  width: 100% !important;
  margin-right: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.btn {
  font-family: Muli-Bold;
}

.btn-primary.btn-danger {
  background-color: $btn-danger-color;
}

.fa-text {
  padding-left: 5px;
}

.fill-box {
  background-color: lightgrey;
  padding: 0px 8px;
  margin-right: 2px;
  font-size: 5px;
}

.fill-box-green {
  background-color: #5cc74d;
}

.notice-box {
  background: url(../images/notice.svg) no-repeat left center;
  padding-left: 30px;
}

.word-break-all {
  word-break: break-all
}

.word-break-word {
  word-break: break-word;
}

.wrap_link.baseline {
  align-items: baseline;
}

.wrapper_link.clear-margin {
  margin: 0 !important;
}

.qs-config-left {
  padding-right: 20px !important;
}

.qs-config-right {
  padding-left: 20px !important;
}

.clear-padding {
  padding: 0 !important;
}

@media(max-width: 767px) {
  .qs-config-left {
    padding-right: 0px !important;
  }
  .qs-config-right {
    padding-left: 0px !important;
  }
  .qs-scroll {
    padding-right: 20px;
  }
}

em {
  font-style: italic !important;
}

.po-tooltiptext a {
  text-decoration: underline !important;
  color: #fff;
}

.qs-config-icon {
  background: url("../images/circle.svg") no-repeat 10px center !important;
}

.example-link-icon {
  background: url("../images/example_link.svg") no-repeat 10px center !important;
}

.white-popup h2 {
  padding: 20px 20px 10px !important;
}

#configure h2 {
  padding: 15px 0px !important;
}

.white-popup.alert {
  max-width: 600px;
}

.text-italic {
  font-style: italic;
}

.select2-container,
.metrics.participants .box.age input {
  width: 100%;
  max-width: 251px;
}

select.full-width .select2-container {
  margin-bottom: 4px;
  max-width: 100%;
}

.run-form-buttons.bottom {
  display: none;
}

@media (max-width: 800px) {
  .run-form-buttons.bottom {
    display: block;
  }

  .run-form-buttons.left {
    display: none;
  }

  .wrap_link a, .wrap_link button {
    margin-right: 15px !important;
  }
}

.metrics.participants .box.gender .wrap_item label {
  margin-bottom: 15px !important;
}

.completions-po-text {
  color: rgba(50, 60, 71, 0.8);
}

.run-actions {
  display: inline;
}

@media (max-width: 414px) {
  .run-actions {
    display: block;
    margin-top: 10px;
    float: left !important;
    min-width: 275px !important;

    a {
      float: left !important;
      margin-top: 10px !important;
    }
  }

  .task-list-header .search-bar {
    float: left !important;
    margin-top: 10px !important;
  }
}

.task-list-header .form-control.search-input {
  width: 276px !important;
}

.wrap_projects .list-item.transactions {
  height: 100% !important;
  padding: 15px 20px !important;
}

.prescreen-select-box {
  box-sizing: content-box;
  border: none !important;
  color: rgb(34, 153, 221) !important;
  border-bottom: 2px dotted rgb(34, 153, 221) !important;
  border-radius: unset !important;
  width: max-content !important;
  background: none !important;
  margin-right: 10px !important;
  padding: 0 10px !important;
}

@media (max-width: 768px) {
  .metrics.activity .left .wrap_input_text .copy_link_tooltip {
    max-width: 100% !important;
    margin-bottom: 8px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .metrics.activity .left .wrap_input_text .copy_link_tooltip {
    max-width: 324px !important;
  }

  .btn-save-as-draft {
    min-width: 108px !important;
  }
}

.tagify {
  border-radius: 4px !important;
  border: 1px solid #e2e9ef !important;
}

.white-popup {
  position: static !important;
  left: auto !important;
  top: auto !important;
  transform: none !important;
  margin: 50px auto !important;
}

.tagify tag > div::before {
  background-color: #e5e5e5 !important;
}

.run-create-btn {
  margin-right: 0 !important;
}

label.success {
  padding: 5px 15px !important;
  height: auto !important;
  min-height: 40px;
}

.run_detail_tabs .middle_box {
  margin: 0 -20px;
  padding: 20px;
  border-top: 1px solid #e2e9ef;
}

.mfp-close.watch-demo {
  color: #ffffff !important;
  display: block !important;
}

.run-form-buttons.left.po-mt {
  margin-top: -15px !important;
}

.radio-toolbar.po-demographic-checked input[type=checkbox],
.radio-checkbox input[type=checkbox],
.wrap_radio label input[type="radio"] {
  display: block !important;
  opacity: 0;
  position: absolute;
}

.focusable-checkbox {
  outline: 2px solid #CCCCCC;
}

#task-feedback-url.copy_link_tooltip {
  height: 30px !important;
}

.completion_link_input {
  padding: 0 30px !important;
}

.qs-col {
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
}

.number-lifted {
  position: relative;
  bottom: 3px;
}

.set_dashed_ca {
  background: url("../images/set_dashed.svg") no-repeat left 5px;
}

.set_approve_ca {
  background: url("../images/checkbox.svg") no-repeat left 5px;
  background-size: 15px;
}

.unreject_inactive {
  background: url("../images/unreject_inactive.svg") no-repeat;
  background-size: 15px;
  background-position: center left;
}

.radio-checkbox {
  position: relative;
}

.wrap_projects.no-shadow,
.panel.no-shadow {
  box-shadow: none;
}

.no-margin {
  margin: 0 !important;
}

select {
  padding-right: 35px;
}

.no-cursor {
  cursor: default !important;
}

select.full-width {
  max-width: 100% !important;
}

.label-additional-attr {
  background-color: #741b47 !important;
  color: #fff !important;
}

.label-demo {
  background-color: #5cc74d !important;
  color: #fff !important;
}

.po-additional-attribute-checked input[type="checkbox"]:checked ~ * {
  background-color: #741B47 !important;
}

.select2-dropdown {
  border: 1px solid #e2e9ef !important;
}

.platform-deprecated {
  padding-left: 3px;
}

.success-tick.filter-list {
  background-position: 0 5px;
}

.table_wrap {
  .heading-inline {
    width: 40% !important;
  }
}

.heading, .run-form-overview-heading {
  color: rgba(35, 53, 57, 0.6);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.13px;
  width: 100%;
  display: block;
}

.wrap_select.est-time {
  input {
    width: 50% !important;
  }

  select {
    width: 50% !important;
    padding-left: 15px !important;
  }
}

.relative {
  position: relative;
}

.fa-icons-dropdown {
  color: #323c47;
  position: absolute;
  left: 0;
  top: 4px;
  opacity: 0.3;
}

.wrap_input_text .copy_link_tooltip {
  max-width: 380px !important;
}

.sent-message {
  padding: 10px 20px !important;
  box-shadow: 0 1px 0 #e9eff4;
  color: #323c47;
}

.status_bar.cint::before {
  width: 65%;
}

.radio-toolbar label,
.radio-toolbar input[type="checkbox"]:checked ~ * {
  height: auto !important;
  min-height: 42px !important;
}

.metrics.participants .box.education input[type="checkbox"]:checked ~ *,
.top_line.participants .box.education input[type="checkbox"]:checked ~ * {
  padding: 10px 15px !important;
}

.date-filter-selected {
  background: url("../images/passed.svg") no-repeat center center !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
}

.swal-button--cancel {
  padding: 11px 20px !important;
}

.buttons-blocker {
  position: relative;

  .buttons-blocker-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0.4;
  }
}

button[disabled] {
  pointer-events: none;
}

.error {
  padding: 15px !important;
  background: $red-soft !important;
  border-radius: 5px;
  color: #fff !important;
  margin-bottom: 15px !important;
}

.error-o {
  padding: 10px !important;
  color: $dark-red !important;
  border: 1px solid $red-soft;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.wrap_info textarea {
  min-width: 100%;
  padding: 10px 15px;
  height: 100px;
}

.color {
  color: #7ab772 !important;
}

.runlist {
  .user_block {
    &:before {
      width: 0;
    }
  }
}

.cc-slash p {
  position: relative;
  bottom: 8px;
}

.add_platform .panel-body .help-block {
  margin-bottom: 0 !important;
}

.Toastify {
  &__toast {
    border-radius: 4px !important;
    padding-left: 16px;
  }

  &__toast-container {
    width: 330px !important;
  }

  &__toast-body {
    padding-right: 1px;
  }
}

main {
  min-height: 100vh;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.fa-icons-dropdown {
  color: #323c47;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}

#datetimepicker1,
#datetimepicker2 {
  position: relative;

  .input-group-addon {
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
}

#startDate::placeholder,
#endDate::placeholder{
  position:relative;
  top:-2px;
}

.message {
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  font-family: Muli-SemiBold;
  font-size: 14px;
}

.hide {
  display: none !important;
}

input:disabled {
  background: rgba(226, 233, 239, 0.5) !important;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }
}

.ca-text-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > div {
    margin-left: 10px;
  }
}

.cus-attr-tagify-input {
  max-width: none !important;
}

.custom-tagify-suggestions {
  & > div {
    max-height: 300px;
    border: 2px solid pink;
    overflow-y: auto;
  }

  .tagify__dropdown__wrapper {
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 4px 4px;
    border: 1px solid #e2e9ef !important;
    padding: 15px;
  }
}

.img-image-check {
  max-height: 350px;
}

.pe-none {
  pointer-events: none;
}

.text-bold {
  font-family: Muli-Bold;
}

.swal-footer, .swal-text {
  text-align: center;
}

.swal-button {
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  min-height: 40px;
}

.swal-button--confirm {
  background-color: #3180e2;
}

.swal-button--cancel {
  color: #548dcd;
  background-color: #fff;
  border: 1px solid #3180e2;
  margin-right: 15px;
}

.swal-button--cancel:hover, .swal-button--cancel:not([disabled]):hover {
  color: #fff;
  background-color: #548dcd;
  border: 1px solid transparent;
}

.checkbox-long-label {
  flex-shrink: 0;
}

.notice.notice-wo-icon {
  background-image: none;
  padding: 10px;
  border: 1px solid #d7d2d2;
  margin-left: 0;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
}

.pii-link {
  color: inherit;
  text-decoration: underline !important;
}

.research-type-container,
.prescreen-input-container {
  input {
    height: auto !important;
    min-height: 40px !important;
    margin-bottom: 0 !important;
  }
}

.po-note {
  color: #757c84;
  font-family: Muli-SemiBold;
}

.Toastify__toast {
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 48px;
}

.add-card-link {
  color: white;
  text-decoration: underline !important;
  font-weight: bold;
  font-family: Muli-Bold;
}

.swal-link {
  color: #307fe2;
  font-family: Muli-Bold;
}

.react-tooltip-po {
  color: #fff !important;
  background-color: #555 !important;
  text-align: center;
  border-radius: 5px !important;
  margin-top: -5px !important;
  padding: 11px !important;
  opacity: 1 !important;
  font-family: Muli-SemiBold;
  font-size: 14px !important;
  max-width: 400px;

  &.place-top {
    &:after {
      border-top-color: #555 !important;
      border-top-style: solid !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: #555 !important;
      border-right-style: solid !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: #555 !important;
      border-bottom-style: solid !important;
    }
  }

  &.place-left {
    &:after {
      border-left-color: #555 !important;
      border-left-style: solid !important;
    }
  }
}

.h-auto {
  height: auto !important;
}

.ca-text-filter-oneOf-value-select {
  input {
    height: auto !important;
    margin-bottom: 0 !important;
  }
}

.rejected-count {
  font-size: 15px !important;
  line-height: 1.05rem;
}

.btn-primary.btn-danger:hover {
  background-color: $btn-danger-color !important;
}

.dropdown-menu.align-menu-items li a {
  line-height: 1.2rem !important;
  padding-top: 6px;
  padding-bottom: 6px;
  display: inline-block;
}

.dropdown-menu.align-menu-items .id_top, .dropdown-menu.align-menu-items .give,
.dropdown-menu.align-menu-items .send, .dropdown-menu.align-menu-items .set_dashed_ca {
  background-position-y: center;
}

.top-0 {
  top: 0;
}

.alert.alert-info-message h3 {
  font-size: 18px;
  font-family: Muli-Bold;
  line-height: 1.25;
  margin-bottom: 10px;
}

.swal-primary-filled {
  background-color: #3180e2 !important;
  color: #fff !important;
  padding: 0px 24px !important;
}

.info-with-border {
  border-radius: 5px;
  border: 1px solid rgba(48, 127, 226, 0.1);
}

::placeholder {
  color: $placeholder-color;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-color;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder-color;
}

.highlight-box {
  background-color: #fffeeb !important;
  outline: 1.5px solid #f8da6f;
  border-radius: 4px !important;
  animation: pulse-animation 1s linear 0s 1 normal forwards;
  max-width: 100%;
}

@keyframes pulse-animation {
  0% {
    outline-color: #faf8d3;
  }
  50% {
    outline-color: #f8da6f;
  }
  75% {
    outline-color: #faf8d3;
  }
  100% {
    outline-color: #f8da6f;
  }
}

.select-placeholder {
  color: $placeholder-color !important;
}

.select-placeholder option {
  color: $input-text-color !important;
}

.react-toastify-link {
  color: white;
  text-decoration: underline !important;
}

.metrics-separator {
  width: 1px;
  background-color: #eaedf1;
  height: 56px;
  transform: translateY(5%);
  margin-left: 10px;
  margin-right: 10px;
}

.no-data-found {
  background-color: rgba(231, 235, 240, 0.5) !important;
}

.unsubscribe label {
  cursor: pointer;
  display: block;
}

.bonus-list {
  height: auto !important;
  padding: 10px 20px !important;
}

#validate-link {
  max-width: 618px;
}

.no-bg {
  background: none !important;
}

.current-attributes-list-item {
  padding: 5px 20px !important;
  word-break: break-all !important;
}

.est-screening-time {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.est-screening-time-type {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.light-gray-box {
  background-color: #ebebeb;
  border: 1px solid #d7d7d7;
  padding: 10px 16px;
  border-radius: 4px;
}

.border-grey {
  border-color: #d7d7d7 !important;
}


.est_max_budget, .est_incidence_rate {
  position: relative;
}

.est_incidence_rate::after {
  position: absolute;
  content: "%";
  right: 26px;
  color: #757c84 !important;
  line-height: 39px;
}

.est_max_budget::before {
  position: absolute;
  content: "USD $";
  width: 60px;
  top: 1px;
  color: #757c84 !important;
  line-height: 39px;
  background-color: rgba(226, 233, 239, 0.5);
  text-align: center;
  padding-left: 2px;
}

.est_max_budget > input {
  padding-left: 75px !important;
}

.text-underline {
  text-decoration: underline !important;
}

#screenin-url, #screenout-url {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  color: var(--input-text-color);
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-SemiBold;
  margin: 5px 0 10px 0;
}

.screening-setup-popup, .interlock-quota-selection {
  max-width: 800px;
  width: 100%;
  position: static !important;
  left: auto !important;
  top: auto !important;
  transform: none !important;
  margin: 50px auto !important;
}

.screening-setup-popup .header, .interlock-quota-selection .title {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px !important;
  letter-spacing: -.57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c5c9cd;
  padding: 5px 0 15px 0;
}

.pad-vert-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.screening-setup-popup span {
  letter-spacing: -0.4px;
}

.text-orange-important {
  color: #e67e22 !important;
}

.radio-checkbox .checkbox:checked + .green-checkbox {
  border-color: #5cc74d;
}

.radio-checkbox .checkbox:checked + .green-checkbox::before {
  background: url("../images/checkbox_green.svg") no-repeat center center;
}

.answer-separator {
  border-top: 1px solid #e2e9ef;
  display: flex;
  align-items: center;
}

.answer-separator:first-of-type {
  border-top: none;
}

.answer-separator .edit-icon {
  min-width: 31px;
  margin-left: auto;
  background: url("../images/configure.svg") no-repeat center right !important;
  width: 30px;
  height: 30px;
}

.title.screening {
  background: rgba(226, 233, 239, 0.5) url("../images/filter.svg") no-repeat 20px center !important;
}

.align-vertical {
  margin-left: 4px;
}

.run-cost .col-4:nth-child(2) {
  text-align: right;
  padding-right: 1.5rem;
}

.run-cost-separator {
  border-top: 1px solid #d5d5d5;
  margin-left: 1.4rem;
  margin-right: 1.4rem;
  margin-top: 5px;
}

@media(min-width: 540px) {
  .run-cost {
    width: 70%;
  }
}

.screening-setup.success-tick-filled {
  background-position: center;
  min-width: 44px;
}

.minus {
  position: absolute;
  top: -10px;
  font-size: 18px;
  left: 2px;
}

@media (max-width: 1199px) {
  .screening-info {
    display: block !important;
  }

  .screening-info-m {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .screening-info {
    display: none !important;
  }

  .screening-info-m {
    display: block !important;
  }
}

.run-info-panel {
  max-width: 550px !important;
}

.run-info-uid {
  margin: 0 30px !important;
}

.color-half-black {
  color: #555;
}

.highlight-control {
  border-radius: 4px;
  background-color: #ffffa9;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 1px solid darken(#eade90, 15%);
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

@keyframes flash {
  0%, 50%, 100% {
    background-color: #ffffa9;
  }
  25%, 75% {
    background-color: white;
  }
}

.flash {
  animation-name: flash;
}

.position-relative {
  position: relative !important;
}

.announcement-wrapper {
  background-color: #FFCC00;
}

.announcement-wrapper-inner {
  border-bottom: 1px solid darken(#FFCC00, 12%);
}

.announcement {
  display: flex;
  align-items: center;
  font-family: Muli-SemiBold;
  font-size: 14px;
  color: #343a40 !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.announcement:last-of-type {
  border-bottom: none;
}

.confirmation-step-title {
  font-size: 16px !important;
}

.po-header-lite {
  font-size: 24px;
  font-family: Muli;
  color: #757c84;
  letter-spacing: -0.86px;
}

.po-header-bold {
  font-size: 24px;
  font-family: Muli-Bold;
  color: #323c47;
  letter-spacing: -0.86px;
}

.po-header-semibold {
  font-size: 24px;
  font-family: Muli-SemiBold;
  color: #757c84;
  letter-spacing: -0.86px;
}

.d-inline-flex {
  display: inline-flex;
}

.screening-feedback-panel {
  background-color: lightgrey;
  padding: 30px;
}

.flex-column {
  flex-direction: column;
}

.screening-feedback-box {
  box-shadow: none !important;
  padding: 0 !important;
  background-color: inherit !important;
}

.gt-link-integration {
  color: #323c47 !important;
  font-size: 16px !important;
}

.gt-link-integration.success-tick {
  background-position-y: calc(50% + 0.1em);
  padding-left: 25px;
  background-size: 20px;
}

.text-grey {
  color: #757c84 !important;
}


.context-screenout-message .font-size-22,
.context-screenout-message .font-size-18,
.context-screenout-message .lh {
  line-height: 1.25em;
}

.font-size-22 {
  font-size: 22px !important;
}

.context-screenout-message .ls {
  letter-spacing: -0.25px;
}

/* Progress bar */
.dot-progress-bar {
  flex-direction: column;
  align-items: start !important;
  justify-content: center;
}

/* Dot Progress bar - Start */
.dot-progress {
  border-bottom: 1px solid #e9eff4;
  box-shadow: none !important;
}

.dot-progress-bar ul {
  width: 85%;
  margin-top: 10px;
}

.dot-progress-bar li {
  position: relative;
  list-style-type: none;
  float: left;
  width: 10%;
  background-color: white;
}

.dot-progress-bar li:before,
.dot-progress-bar li.last:before {
  content: '';
  width: 14px;
  height: 14px;
  border: 1px solid #c9edc4;
  border-radius: 50%;
  display: block;
  background: #c9edc4;
}

.dot-progress-bar li:after,
.dot-progress-bar li.last:after {
  content: '';
  position: absolute;
  width: calc(100% - 16px);
  height: 2px;
  background: #c9edc4;
  top: 7px;
  left: calc(16px - 100%);
}

.dot-progress-bar li span,
.dot-progress-bar li.last span {
  content: '';
  position: absolute;
  background: #5fc850;
  border-radius: 50%;
  height: 8px !important;
  width: 8px !important;
  left: 4px;
  top: 4px;
}

.dot-progress-bar li:first-child:after {
  display: none;
}

.dot-progress-bar li.current:before {
  background: #c8dffb !important;
  border: 1px solid #c8dffb !important;
}

.dot-progress-bar li.current span {
  background: #307fe2 !important;
}

.dot-progress-bar li.current ~ li:before {
  background: #edeeef !important;
  border: 1px solid #bebebe !important;
}

.dot-progress-bar li.current ~ li:after {
  background: #edeeef !important;
}

.dot-progress-bar li.current ~ li span {
  background: #c4cacc !important;
}


.run-progress .dot-progress:nth-child(odd) {
  background-color: #fbfbfb;
}

.run-progress .list-item > div {
  margin-bottom: 0px;
}

@media (max-width: 576px) {
  .run-progress .current-status,
  .dot-progress-bar ul {
    display: none;
  }

  .run-progress .list-item > div {
    margin: 0 !important;
  }
}

/* Dot Progress bar - End */

.col-gap {
  column-gap: 0.75rem;
}

.run-info-actions {
  flex: auto;
}

.run-feedback-actions {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .wrapper_link.bulk-actions {
    flex-direction: row;
  }

  .wrapper_link.bulk-actions a {
    margin-left: 10px;
  }

  .run-feedback-actions {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .run-info-panel {
    flex-basis: auto;
    min-width: 100%;
  }

  .run-progress .list-item {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .run-progress-review-link {
    text-align: right;
  }
}

.action.user_block, .d-flex {
  display: flex;
}

.bulk-actions a {
  width: fit-content;
}

.test-status {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.test-status > div {
  padding: 3px 0;
}

.test-status > div > span {
  position: relative;
}

.test-status .success-tick-filled {
  background-position-y: 3px;
  background-size: 17px;
  padding-left: 24px;
}

.test-status > div > span:not(.success-tick-filled) {
  padding-left: 24px;
}

.test-status > div > span {
  position: relative;
}

.test-status > div > span:not(.success-tick-filled)::after {
  content: '';
  margin-top: 1px;
  position: absolute;
  border: 2px solid rgba(35, 53, 57, 0.5);
  left: 0;
  top: calc(50%);
  transform: translateY(-50%);
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #ddd;
}

.lh-1 {
  line-height: 1.125rem;
}

.lh-125 {
  line-height: 125%;
}

.lh-1-5 {
  line-height: 1.5rem;
}

.status-count {
  .col-md-4.custom-mt {
    display: flex;
    margin-top: 0 !important;
  }
}

.countdown {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .countdown-value {
    font-size: 26px;
    position: relative;
    padding: 0 10px;

    .countdown-label {
      position: absolute;
      font-size: 14px;
      top: 85%;
      left: 50%;
      transform: translateX(-50%);
      color: $red-soft;
    }
  }
}

.run-stats-label {
  font-size: 13.4px !important;
}

.comment-group {
  padding-left: 8px;
  padding-bottom: 8px;
  box-shadow: 0 1px 0 #e9eff4;
}

.comment-group:last-of-type {
  padding-bottom: 0;
  box-shadow: none;
}

.action-link {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: row;
}

@media (max-width: 768px) {
  .highlight-stats {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.info-alert {
  padding: 0.75rem 1.25rem;
  color: #1d5761;
  background-color: #e8fbff;
  border: 1px solid #bee5eb;
  border-radius: 5px;
}

.react-tooltip-po.action-menu-item {
  margin-top: 0 !important;
  margin-left: 0px !important;
}

.radio-checkbox input[type=checkbox]:focus + .checkbox-custom {
  border: 2px solid $blue;
}

.gt-positly-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gt-promo-banner {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.25rem;
}

.gt-promo-close-btn {
  opacity: 0.5;
  font-size: 1.375rem;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: -4px;
}

.gt-promo-close-btn:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .gt-promo-banner {
    flex-direction: column;
  }

  .gt-positly-logo {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .gt-cta-btn {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .gt-positly-logo {
    flex-direction: row;
  }
}

.po-ns {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.pre-survey-answers.po-box .po-ns-select:last-child {
  position: absolute;
  right: 110vw;
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.highlight-filter {
  animation: zoom-in 300ms 1;
}

.remove-filter {
  animation: zoom-out 300ms 1;
}

.filter-wrapper, .quota-wrapper {
  background-color: #d0d0d0;
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.filter-box, .quota {
  position: relative;
  margin-left: 2rem;
  width: calc(100% - 2rem) !important;
}

.filter-box .scroll-wrapper, .quota .scroll-wrapper {
  max-height: 600px;
  overflow-y: auto;
}

.filter-box::before, .quota::before {
  position: absolute;
  font-family: "Font Awesome 5 Free", serif;
  content: "\f0b0";
  font-weight: 900;
  top: 50%;
  transform: translateX(-50%);
  left: -1.25rem;
  color: #666666;
}

.quota::before {
  content: "\f200";
}

.quota-table table {
  border-collapse: collapse;
}

.quota-table tr:not(.header):not(.footer):nth-child(even) {
  background-color: #f5f5f5;
}

.quota-table tr:not(.header):not(.footer) {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.quota-table tr.header td, .quota-table tr.footer td {
  background: transparent !important;
  border: none;
}

.quota-table tr.header td {
  border-bottom: 1px solid #e5e5e5;
}

.quota-table tr.footer td {
  padding-top: 0.25rem;
  border-top: 1px solid #e5e5e5;
}

.quota-table td {
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.5rem;
}

.quota-table td:first-child {
  text-align: left;
  width: 100%;
  padding-left: 0.75rem;
}

.quota-table input {
  height: 34px !important;
  width: 60px !important;
  margin-bottom: 0 !important;
  padding-left: 8px !important;
}

.text-align-right {
  text-align: right !important;
}

.percent-wrapper {
  display: flex;
}

.percent-wrapper input {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 60px !important;
}

.percent-wrapper span {
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808fa3;
  background-color: rgba(226, 233, 239, 0.5);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #e2e9ef;
  border-left: none;
}

.quota-table.form_style span {
  margin-top: 0;
}

.gap-0-5 {
  gap: 0.5rem;
}

.pos-sticky {
  position: sticky !important;
  top: 5px;
}

.btn-sm {
  height: 32px !important;
  padding: 0 8px !important;
}

.quota .ion-close {
  top: 26px !important;
}

.interlock-example-title {
  width: 70%;
  text-align: center;
  color: white;
  padding: 10px;
}

.bg-gray {
  background-color: gray;
}

.align-center {
  text-align: -webkit-center;
}

#interlock-quota-selection {
  max-width: 850px;
}

.notice.notice-border {
  border: 1px solid #f8da6f;
  margin-left: 0;
  width: 100%;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.info-toast {
  border: 2px solid #f8da6f;
  color: #3d4c50 !important;
  font-family: Muli-SemiBold !important;
  letter-spacing: -0.37px;
  background: #fffeeb;
  margin-left: 20px;
  margin-right: -20px;
  display: flex;
  align-items: center;
}

.quota-arrow {
  color: #444;
  font-weight: bold;
  font-size: 2.5rem;
}

.quota-box {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 1rem 1rem 0 1rem;

  .quota-table td {
    padding: 0.25rem 0.5rem;
  }

  .quota-table td:not(.percent-wrapper) span {
    color: #343a40;
  }

  .percent-wrapper input {
    width: 46px !important;
  }
}

.compact-table .percent-wrapper input {
  height: 28px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.interlock-choice {
  .quota-title {
    width: 100%;
    padding: 0.5rem 0.75rem;
    margin: 0 0.5rem;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }

  .quota-choice .checkbox {
    margin-bottom: 0;
  }

  .quota-choice:first-child .quota-title {
    border-top: 1px solid #e5e5e5;
  }

  .quota-choice:last-child .quota-title {
    border-bottom: 1px solid #e5e5e5;
  }

  .quota-choice:nth-child(odd) .quota-title {
    background-color: #f5f5f5;
  }

  .checkbox:disabled + span.checkbox-custom {
    background-color: #e5e5e5;
  }
}

.w-70 {
  width: 70% !important;
}

.tagify__input::before {
  color: $input-text-color;
}

.btn-default.btn-outline-danger {
  color: $btn-danger-color !important;
  border-color: $btn-danger-color !important;
}

.btn-default.btn-outline-danger:hover {
  color: white !important;
  background-color: $btn-danger-color !important;
}

.cint-overview-icon {
  color: $placeholder-color;
  margin-left: 0.375rem;
}

.quota-name-list li {
  list-style-position: inside;
  list-style-type: disc;
}

.interlocked-quota::before {
  content: "\f0c1";
  transform: rotate(-45deg);
  left: -1.75rem;
}

.interlock-icon {
  transform: rotate(-45deg);
}

.participant-attribute-title {
  font-size: 14px !important;
  letter-spacing: -0.4px !important;
}

.light-color-icon::before {
  color: $placeholder-color !important;
}

.mfp-container:has(.demo-video-container) {
  height: 100% !important;
}

.demo-video-container {
  background: none !important;
  max-width: calc(60vw - 60px);
  height: max-content;

  .iframe-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: calc(60vh - 60px);

    iframe {
      aspect-ratio: 16/9;
      margin: 0 auto;
    }
  }
}

@media (max-width: 767px) {
  .demo-video-container {
    max-width: calc(100vw - 60px);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(11, minmax(50px, 1fr));
  grid-column-gap: 2px;
  width: 100%;

  .grid-item.no-style:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
  }
}

.grid-container-mobile {
  grid-template-columns: max-content max-content;
  grid-template-rows: repeat(7, 55px);
  grid-auto-flow: column;

  .grid-item:nth-child(8n + 1) {
    grid-column: span 1;
  }

  .grid-item.header {
    border-bottom: 1px solid #fff;
  }

  .grid-item.no-style {
    justify-content: center;
  }
}

@media (max-width: $mediumScreenMaxWidth) {
  .platform-select-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .platform-select-item {
      min-width: 155px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 1rem;
      }
    }

    .platform-title {
      width: 100%;
    }

    .grid-item.header {
      flex-direction: row;
      justify-content: left;
      padding: 5px 20px;

      img {
        padding: 0 4px;
      }
    }
    align-items: center;
  }

  .run-completion-bot-section, .run-quality-filter-section {
    margin-top: 1rem !important;
  }

  .completion-details {
    margin-top: 0 !important;
  }

  .quality-quard {
    margin-top: 0 !important;
  }
}

.grid-item {
  background-color: #368fff;
  width: 100%;
}

.grid-item img {
  object-fit: contain;
  padding: 4px 8px;
}

.grid-item.header img {
  width: 40px;
  margin-bottom: 5px;
}

.grid-item span {
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  font-family: Muli-SemiBold, sans-serif;
  display: inline-block;
  text-align: center;
}

.grid-item.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 5px 10px;
}

.grid-item.row {
  position: relative;
  background-color: #eef4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid $blue;
  min-height: 60px;

  @media(max-width: $mediumScreenMaxWidth) {
    min-height: auto;
    border-top: 1px solid $blue;
    padding: 5px 20px;

    &:last-child {
      border-bottom: 1px solid $blue;
    }
  }
}

.grid-item.row img {
  width: 35px;
}

.grid-item.row::before {
  position: absolute;
  content: '';
  width: 10%;
  height: 2px;
  top: -2px;
  left: -2px;
  background-color: $blue;
}

.grid-item.no-style {
  background-color: transparent;
  border: none;
  justify-content: right;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.grid-item.no-style::before, .grid-item:nth-child(9n + 2)::before, .grid-item:nth-child(-n+20)::before {
  display: none;
}

.grid-item:nth-child(8n + 1) {
  grid-column: span 4;
}

.platform-select {

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-right: 28px;
    cursor: pointer;
    line-height: 16px;
    font-size: 14px;
    font-family: Muli-SemiBold, sans-serif;
    display: inline-block;
    color: $input-text-color;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid $blue;
    border-radius: 100%;
    background: #fff;
    transform: translateY(-48%);
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $blue;
    position: absolute;
    top: calc(50% - 5.5px);
    right: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .platform-select-mobile {
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding-right: 0;
      padding-left: 28px;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      left: 0;
      right: auto;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      right: 0;
      left: 5px
    }
  }
}

.quality-filter {
  .grid {
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
    justify-content: center;
  }

  .filter-options {
    .quality-filter-item:not(:first-child) {
      margin-top: 12px;
    }
  }
}

@media (max-width: 767px) {
  .quality-filter .grid {
    display: flex;
    margin-top: 10px;
  }
}

.auto-block-bots {
  .switch {
    width: 54px;
    height: 29px;
  }

  .slider:before {
    height: 22px;
    width: 22px;
    bottom: 3px;
    left: 3px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.flex-no-shrink {
  flex-shrink: 0 !important;
}

.screening-link-wrapper input {
  height: 30px !important;
  background-color: rgba(234, 237, 241, 0.5) !important;
  margin-right: 5px !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ps-text-area {
  height: auto !important;
  padding: 10px 20px !important;
  margin-top: -28px;
}

.ps-success-tick {
  display: inline-block;
  background-position: center;
  width: 30px;
}

.pre-survey-answers label div span:not([class]) {
  color: transparent !important;
}

h2.pre-survey-question {
  font-size: 15px;
}

/* Navbar */
.nav {
  box-shadow: 0 1px 6px transparent;
  background-color: #307fe2;
  margin-bottom: 20px;
  position: sticky !important;
  top: 0;
  z-index: 999;

  a {
    cursor: pointer;
  }

  .container {
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .logo {
    flex-shrink: 0;
  }

  .nav-menu {
    display: flex;
    align-self: stretch;
    margin-left: auto;
    padding-right: 1.5rem;
    position: relative;
  }

  .nav-menu ul {
    align-self: stretch;
    display: flex;
    gap: 1rem;
    text-align: center;
  }

  .nav-menu li {
    display: flex;
    position: relative;
  }

  .nav-menu ul a {
    align-self: center;
    color: rgba(255, 255, 255, 0.85);
    font-family: Muli-Bold, serif;
    font-size: 14px;
    transition: .5s;
    padding: 0.25rem 0.5rem;
    position: relative;
  }

  .nav-menu ul a:hover, .nav-menu ul a.active {
    color: white;
  }

  .nav-menu li:has(a.active)::before {
    position: absolute;
    content: '';
    height: 3px;
    width: calc(100% - 1rem);
    background-color: white;
    bottom: 0;
    left: 0.5rem;
  }

  .menu-icon {
    width: 28px;
    display: none;
    color: white !important;
    cursor: pointer;
  }

  .close-icon {
    display: none;
    cursor: pointer;
  }

  .close-icon {
    color: rgba(255, 255, 255, 0.8);
    right: 0;
    margin: 0.25rem 0.75rem 0 0;
    padding: 0 0.5rem 0.5rem 0.5rem;
    flex-grow: 0;
    position: absolute;
    font-size: 30px;
  }

  .nav-more {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: Muli-SemiBold, serif;
    position: relative;
    padding-left: 0.5rem;
  }

  .nav-more.has_menu:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.85);
    left: -1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .nav-more i {
    margin-left: 6px;
  }

  .username {
    color: #fff !important;
    font-size: 14px;
    transition: .5s;
    text-align: right;
  }
}

@media(max-width: 767px) {
  .nav {
    .logo img {
      max-width: 95%;
    }

    .menu-icon {
      display: initial;
    }

    .close-icon {
      display: initial;
    }

    .username {
      font-size: 15px;
    }

    .nav-menu {
      display: flex;
      position: fixed;
      background-color: #307fe2;
      width: 100%;
      top: -100%;
      left: 0;
      right: 0;
      padding-right: 0;
      z-index: 9999;
      transition: top .4s;
      box-shadow: 0px 5px 9px 1px rgba(191, 214, 242, 1);
    }

    .nav-menu ul {
      width: 100%;
      flex-direction: column;
      padding: 2rem;
      align-items: center;
    }

    .nav-menu li:has(a.active)::before {
      height: 2px;
    }

    .nav-menu li a {
      font-size: 16px;
    }

    .nav-menu.visible {
      top: 0;
    }

    .nav-more {
      margin-left: auto;
    }

    .nav-more:before {
      display: none;
    }
  }
}

.lh-125p {
  line-height: 125% !important;
}

.greeting {
  font-size: 30px;
  letter-spacing: -0.86px;
  font-family: 'Muli-SemiBold', serif !important;
  color: #323c47 !important;
  margin-top: -2px;
  margin-right: 1rem;
}

.gap-1 {
  gap: 1rem;
}

.project-list-btns, .help-btns {
  .outline-btn, .primary-btn {
    color: #3180e2;
    font-size: 14px;
    letter-spacing: 0.13px;
    font-family: Muli-Bold, serif;
    padding: 8px 14px 9px 14px;
    border-radius: 4px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    border: 1px solid #548dcd;
    width: max-content;
    flex-shrink: 0;
  }

  .primary-btn {
    color: #ffffff;
    background-color: #3180e2;
    padding: 11px 16px 12px 16px;
  }

  .outline-btn:hover, .primary-btn:hover {
    color: #ffffff;
    background: #3180e2 !important;
    opacity: .7;
    transition: .5s;
  }
}

.project-list-btns {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  .btns {
    display: flex;
    gap: 1rem;
  }

  .outline-btn, .primary-btn {
    font-size: 16px;
    padding: 12px 16px 13px 16px;
  }
}

.help-btns {
  display: inline-flex;
  gap: 1rem;
}

#request-demo-popup {
  textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e2e9ef;
    background-color: #ffffff;
    color: #323c47;
    font-family: Muli-SemiBold, serif;
    font-size: 14px;
    padding-left: 15px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .greeting {
    font-size: 22px;
  }

  .project-list-btns {
    flex-direction: column;
    align-items: start;

    .btns {
      flex-direction: column;
    }

    .outline-btn, .primary-btn {
      font-size: 14px;
    }
  }

  form.project-search {
    width: 100%;
  }

  .help-btns {
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
  }

  .run-form-title-wrapper {
    flex-direction: column;
    align-items: start !important;
  }

  .run-form-help-btns {
    flex-direction: column;
  }
}

.project-list-header {
  display: flex;
  justify-content: space-between;
}

.stage-container {
  min-width: calc(50% - 21px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  gap: 2.5rem;
  position: relative;
}

.stage-container::before {
  position: absolute;
  content: '';
  width: 1px;
  height: calc(100% - 8px);
  background-color: #e2e9ef;
  left: 0;
  top: 8px;
}

@media (max-width: 767px) {
  .stage-container::before {
    width: 0;
  }
}

.stage-container.mobile {
  display: none;
}

.stage-item {
  text-align: center;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.stage-item > div:first-child {
  position: relative;
}

.stage-item > div:first-child::after {
  content: '';
  position: absolute;
  top: calc(100% + 0.625rem);
  left: calc(50% - 0.5rem);
  height: 1.5rem;
  width: 1rem;
  background-image: url("../images/cl-setup-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.stage-item:last-child > div:first-child::after {
  display: none;
}

.stage-item img {
  max-width: 80px !important;
  height: auto !important;
}

.radio-checkbox .checkbox-custom {
  min-width: 20px;
  min-height: 20px;
}

.min-chars-help {
  max-height: 3.25rem;
}

.status_bar .item p {
  text-align: center;
}

.positly-link {
  font-size: 13px;
  line-height: 28px;
  height: 30px;
  border-radius: 4px;
  background: rgba(234, 237, 241, .5) url("../images/link_done.svg") no-repeat 8px 9px;
  border: 1px solid #e2e9ef;
  padding-left: 26px;
  padding-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100% !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}


.quality-guard-inputs {
  .form-group {
    input {
      width: 100% !important;
    }

    select {
      width: 100% !important;
      max-width: none !important;
    }

    .percent-input {
      display: flex;

      input {
        width: calc(100% - 40px) !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      div {
        border: 1px solid #e2e9ef;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(226, 233, 239, 0.5);
        height: 40px;
        width: 40px;
      }
    }
  }
}


@media (max-width: 576px) {
  .min-chars-help {
    max-height: 4.25rem;
  }
}

@media (max-width: 767px) {
  .pre-survey-form {
    .metrics.project_detail .left {
      padding-right: 0 !important;
    }
  }

  .stage-container.desktop {
    display: none;
  }

  .stage-container.mobile {
    display: flex;
    border-left: none;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 1.5rem;
    gap: 2rem;

    .stage-item {
      width: 100%;
      gap: 0.25rem;
    }

    .stage-item > div:first-child {
      flex-grow: 1;
    }

    .stage-item > div:first-child::after {
      top: calc(100% + 0.25rem);
    }

    .stage-item > div:nth-child(2) {
      width: 100px;
      flex-shrink: 0;
    }

    .stage-item img {
      width: auto !important;
      max-height: 60px !important;
    }
  }

  .quality-guard-inputs {
    .form-group {
      input {
        width: 100% !important;
      }

      select {
        width: 100% !important;
        max-width: 100% !important;
      }

      .percent-input {
        input {
          width: calc(100% - 40px) !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .list-item.list-heading.ex-attribute-header {
    display: flex !important;
    flex-direction: row !important;
  }

  .run-form > .right {
    margin-top: 1.5rem;
  }

  .project-list-header {
    flex-direction: column;
  }

  .greeting {
    font-size: 26px;
  }

  .project-search {
    width: 100%;
  }

  .outline-btn, .primary-btn {
    font-size: 15px;
  }

  .pre-survey-form {
    .metrics.project_detail .left {
      padding-right: 22px;
    }
  }

  .right .filter-options {
    .quality-filter-item:first-child {
      margin-top: 12px;
    }
  }
}

@media (max-width: 1199px) {
  .help {
    max-width: initial !important;
  }

  .pre-survey-form {
    .metrics.filter .box select, .metrics.filter .box input, .metrics.filter .box textarea, .metrics.filter .box h2 {
      font-size: 14px;
    }
  }
}

.react-select__input {
  height: 40px !important;
}

.list-item.infinitely-loaded:hover {
  background-color: #e9eff4;
}

@media (max-width: $extraSmallScreenMaxWidth) {
  .mt-xs-3 {
    margin-top: 1rem !important;
  }
}

.list-item,
.feedback-item {
  position: relative;
}

.copy_survey_link {
  min-height: 30px;
  height: auto !important;
}

@media (max-width: 767px) {
  .copy_survey_link {
    cursor: pointer;
    margin-top: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.p-10x {
  padding: 10px;
}

.pl-30x {
  padding-left: 30px;
}

.no-shadow {
  box-shadow: none !important;
}

.notice.warn {
  border: 1px solid #d4a72c66;
}

.btn-primary.btn-begin-survey {
  display: initial;
  padding: 10px 20px;
}

.list-item .right-space {
  width: 90%;
}

@media (max-width: $mediumScreenMaxWidth) {
  .list-item .right-space {
    width: 100%;
  }
}

@media (max-width: $mediumScreenMaxWidth) {
  .quality-guard-inputs {
    padding-left: 0 !important;

    .quality-quard-fields {
      margin-top: 1rem;
    }
  }
}

@media (min-width: $mediumScreenMaxWidth) {
  .quality-quard {
    margin-top: 10px !important;
  }
}

.run-completion-box .completion-details,
.cint-auto-features-info {
  padding-right: 22px;
}

.completion-details {
  input {
    margin-bottom: 0 !important;
  }

  .estimated-time-select {
    max-width: none !important;
  }
}

.run-completion-box {
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.run-form-gt-promo-row {
  .country-section {
    margin-top: 6px !important;
  }

  .language-section {
    margin-top: 14px !important;
  }

  .box.gt-promo {
    height: 100%;
  }
}

.run-completion-box {
  .eligible-participants-section, .personal-identification-section {
    margin-top: 20px !important;
    flex-direction: row;
    width: 100%;

    .personal-identification-label {
      margin-bottom: 0;
    }
  }
}

.very-low-pay .form-control, .very-low-pay .form-control:focus {
  border: 2px solid $orange !important;
  box-shadow: 0 0 5px $orange !important;
  outline: none;
}

.low-pay .form-control, .low-pay .form-control:focus {
  border: 2px solid $dark-yellow !important;
  box-shadow: 0 0 5px $dark-yellow !important;
  outline: none;
}

.text-dark-yellow {
  color: $dark-yellow;
}

.react-tooltip-po.tooltip-light {
  color: $dark !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:after {
    border-color: #fff !important;
  }
}

@media(max-width: $smallScreenMaxWidth) {
  .wrap_form.expires_after .wrap_right  {
    .invisible {
      display: none;
    }

    select {
      max-width: 100% !important;
    }
  }

  .line_wrap.eligible-participants-section {
    margin-top: 0 !important;
  }
}


.visible-desktop {
  display: none !important;

  @media (min-width: $largeScreenMinWidth) {
    display: block !important;
  }
}

.hidden-desktop {
  display: block !important;

  @media (min-width: $largeScreenMinWidth) {
    display: none !important;
  }
}

.lh-normal {
  line-height: normal !important;
}
