.cs-wrapper {
  position: relative;
  border: 1px solid #e2e9ef;
  border-radius: 4px;
  overflow: hidden;

  /* scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }

  /* searchbar*/
  .cs-search-container {
    position: relative;

    .cs-search-input {
      outline: none;
      border: none !important;
      border-radius: 0 !important;
      width: calc(100% - 2em) !important;
      margin: 5px 1em 0.5em 1em !important;
      height: 30px !important;
      padding-left: 25px !important;
    }

    i.fa.fa-search {
      font-size: 14px;
      position: absolute;
      color: var(--placeholder-color);
      top: 14px;
      left: 1.25em;
    }

    .fa-chevron-down,
    .fa-chevron-up {
      position: absolute;
      top: 15px;
      right: 15px;
      color: var(--placeholder-color);
      cursor: pointer;
    }
  }

  .cs-search-input:focus, .cs-search-input:active {
    border-bottom-color: #2684ff !important;
  }

  .cs-outer-container {
    position: relative;
    display: flex;
    height: 241px;
    background-color: white;
    color: var(--input-text-color);
    font-size: 14px;
    letter-spacing: -0.4;
    font-family: 'Muli-SemiBold';
    border-top: 1px solid #e2e9ef;

    .cs-left-container {
      overflow: auto;
      border-right: 1px solid #e2e9ef;
      min-width: 30%;
      max-width: 40%;

      .cs-group-item:hover {
        background-color: #deebff;
      }

      .cs-group-item.active, .cs-group-item.active:hover {
        background-color: #2684ff;
        color: white;
        position: relative;
      }

      .cs-group-item.active::after {
        position: absolute;
        content: '';
        height: 6px;
        width: 6px;
        border-style: solid;
        border-width: 2px 2px 0 0;
        color: #deebff;
        display: inline-block;
        top: 50%;
        vertical-align: top;
        right: 1em;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    .cs-right-container {
      overflow: auto;
      flex: 1;
    }

    .cs-group-item {
      cursor: pointer;
      min-height: 40px;
      padding: 0.5em 2.25em 0.5em 1em;
      display: flex;
      align-items: flex-start;

      .fa {
        padding-top: 4px;
        padding-right: 5px;
      }
    }

    .cs-group-item:hover {
      background-color: #deebff;
    }

    .cs-group-item.active, .cs-group-item.active:hover {
      background-color: #2684ff;
      color: white;
    }

    .cs-no-options {
      color: var(--placeholder-color);
      padding: 1em 1.5em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
