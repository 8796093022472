@import "breakpoints";

.status_bar .item {
  transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
}

.status_bar .item:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.max-time-per-submission-input:focus {
  margin-right: 1px;
}
