@import "breakpoints";

.platform-tab li a {
  font-size: 20px !important;
}

.assignment-list-item:hover{
  background-color: #e9eff4;
}

@media (max-width: $largeScreenMinWidth) {
  .mb-10-md {
    margin-bottom: 10px;
  }
}

@media (max-width: $mediumScreenMaxWidth) {
  .wrapper_link.right {
    flex-direction: row;
    flex-wrap: wrap;

    a  {
      margin-right: 10px;
    }
  }
}

@media (max-width: $extraSmallScreenMaxWidth) {
  .wrapper_link.right {
    flex-direction: column;
  }
}

@media (max-width: $mediumScreenMaxWidth) {
  .platform-tabs {
    flex-direction: row;
    flex-wrap: wrap;

    .platform-tab {
      margin: 15px 0;
    }
  }
}

.w-90-percent {
  width: 90%;
}
