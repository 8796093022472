$sign-up-page-color: rgba(255, 255, 255, 0.85);

.app.sign-up,
.app.sign-in,
.app.request-password,
.app.reset-password,
.app.contact-us:not(.logged-in) {
  //background-color: #4e79f2;
  //background-image: linear-gradient(to right top, #307fe2, #5d7eee, #847af6, #ab73f9, #d068f7);
  background: url("../images/welcome.avif");
  background-size: cover;

  .nav {
    background: transparent;
  }

  @media (min-width: 768px) {
    .sign-up-box:not(.gt-sign-up),
    .contact-us-box,
    .sign-in-box,
    .request-password-box,
    .reset-password-box {
      margin-top: 6rem;
    }
  }

  .account-type {
    input {
      width: 18px;
      height: auto;
      margin: 0 5px 0 0;
      cursor: pointer;
    }
  }

  .spinner {
    border: 2px solid $sign-up-page-color;
    border-right-color: transparent;
  }

  .copyright {
    color: $sign-up-page-color;
  }
}

.request-password .metrics h1,
.reset-password .metrics h1 {
  color: $sign-up-page-color;
}
