.user_block.download {
  .btn-download {
    border-radius: 4px;
    border: 1px solid #307fe2 !important;
    padding: 0 10px 0 35px;
    color: #307fe2 !important;
    font-size: 14px;
    height: 30px;
    font-family: Muli-Bold;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    transition: .5s;
    background: url("../images/link_2.svg") no-repeat 10px center;
  }

  &:before {
    width: 0;
  }
}
