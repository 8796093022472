$white: #ffffff;
$yellow: #ffff0085;
$red-dark: #e74f4f;
$red-soft: #fc5a5a;

.alert-info-message {
  color: #333;
  background: #ffff0085;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
}

.alert.alert-error {
  background-color: $red-dark;
  border-color: $red-soft;
  color: #ffffff;
}

.text-white {
  color: $white !important;
}
