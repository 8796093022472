.dropdown-menu {
  padding: 0 !important;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: transparent !important;

  &.dropdown-menu-navbar,
  &.dropdown-menu-billing {
    position: absolute;
    top: 15px !important;
    right: 0;
    width: 240px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
    z-index: 9999;

    li {
      padding: 0;
      transition: none;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 5px 16px;
        pointer-events: auto;
      }
      
      a,
      a:active,
      a:visited {
        color: #323c47;
      }
    }
  }

  &.menu_dashed {
    display: none;

    li {
      margin-bottom: 0;

      &:first-child {
        padding: 5px 16px;
        margin: 0;
        cursor: default;
        background-color: #eeeeee;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      a:hover {
        color: #323c47 !important;
      }
    }
  }

  li, .dropdown-item {
    background-color: #fff;
    color: #323c47;
    font-family: Muli;
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 34px;
    padding: 5px 16px;
    border-bottom: 1px solid #e9eff4;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
      border-bottom: 0;
    }

    &:first-child:last-child {
      border-radius: 6px;
    }

    &:hover {
      background: #e9eff4;
      cursor: pointer;
    }

    &.with-spinner {
      display: flex;
      align-items: center;
    }

    a {
      pointer-events: none;
    }

    div {
      line-height: 1.2rem !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    span {
      cursor: pointer;
    }

    a:active,
    a:visited {
      color: #323c47;
    }
  }
}

#dropdownMenu1:not(.run-info-actions) {
  background-position: center !important;
  padding: 20px;
  position: absolute;
  right: 5px;
  top: 10px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(48, 127, 226, 0.3);
  }
}

.list-item .user_block,
.list-item .help_block {
  position: absolute;
  top: 0;
  right: 0;
}
