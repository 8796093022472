.widget-popup {
  position: fixed;
  right: 25px;
  bottom: 85px;
  width: 553px;
  height: auto;
  border-radius: 5px;
  padding: 30px;
  overflow-y: auto;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    // greater than Zoho chat
    z-index: 2247483647!important;
  }

  @media (max-width: 767px) {
    left: 5px;
    right: 5px;
    width: auto;
  }
}
