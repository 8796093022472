.alert {
  padding: 15px;
  text-align: left;
  border-radius: 5px;
  margin: 25px 0;
  color: #000;

  &.info {
    border: 1px solid rgba(48, 127, 226, 0.4);
    background-color: rgba(48, 127, 226, 0.1);
  }

  &.warn {
    background-color: #fffeeb !important;
    outline: 1px solid #f8da6f;
  }
}

