.spinner {
  -moz-animation: three-quarters-loader 1250ms infinite linear;
  -webkit-animation: three-quarters-loader 1250ms infinite linear;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 2px solid #307fe2;
  border-right-color: transparent;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 24px;
  height: 24px !important;
  margin: 0 auto;
}

.white-spinner .spinner {
  border: 2px solid #fff;
  border-right-color: transparent;
}

.inline-spinner {
  display: inline-block;
}

.dropdown-item .inline-spinner .spinner,
.btn .inline-spinner .spinner{
  width: 16px;
  height: 16px !important;
}

.spinner-left .spinner {
  margin: 0;
}

@-moz-keyframes three-quarters-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes three-quarters-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes three-quarters-loader {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.8;
  z-index: 100;
}

.page-blocker .page-blocker-inner {
  position: absolute;
  top: 43%;
  left: 47%;
  z-index: 1000;
  color: #fff;
}

.page-blocker .spinner {
  margin: 10px 0 10px 10px;
}

.page-blocker .spinner > div {
  background-color: #fff;
}

#loading-bar .bar {
  height: 3px !important;
  background-color: #ffffff !important;
}

#loading-bar .bar.blue {
  background-color: #307fe2 !important;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #ffffff !important;
  border-left-color: #ffffff !important;
}

#loading-bar-spinner.blue .spinner-icon {
  border-top-color: #307fe2 !important;
  border-left-color: #307fe2 !important;
}
