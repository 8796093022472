.skip-feedback {
  padding-top: 100px;

  img {
    width: 100px;
  }

  @media (max-width: 767px) {
    img {
      width: 75px;
    }
  }
}
