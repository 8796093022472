@charset "UTF-8";

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --input-text-color: rgba(50, 60, 71, 0.85);
  --placeholder-color: rgba(50, 60, 71, 0.45);
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  width: 100%; }
@media (min-width: 576px) {
  .container {
    max-width: 540px; } }
@media (min-width: 768px) {
  .container {
    max-width: 720px; } }
@media (min-width: 992px) {
  .container {
    max-width: 960px; } }
@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.clickable {
  cursor: pointer;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0; }

.no-decoration:hover {
  text-decoration: none;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 991px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc1078f !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
@media print {
  .d-print-block {
    display: block !important; } }

.d-print-inline {
  display: none !important; }
@media print {
  .d-print-inline {
    display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
@media print {
  .d-print-inline-block {
    display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
.embed-responsive::before {
  display: block;
  content: ""; }
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* start: tooltips */

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}

.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
}

.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
}

.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
/* end: tooltips */


.white-popup {
  max-width: 729px;
  width: 100%;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; }
.white-popup .table_head.row {
  margin: 0 15px;
  background-color: #e5e6e8;
  height: 50px;
  align-items: center; }
.white-popup .table_head.row .col {
  color: #233539;
  font-family: "Muli-Bold";
  width: 100%;
  color: #323c47;
  font-family: "Muli-SemiBold";
  font-size: 14px;
  line-height: 21px;
  padding: 0 25px; }
.white-popup .table_body {
  height: 280px;
  overflow-y: scroll;
  display: block;
  border-collapse: collapse;
  margin: 0 15px;
  border: 1px solid rgba(35, 53, 57, 0.1); }
.white-popup .table_body tr {
  height: 50px;
  border: 1px solid rgba(35, 53, 57, 0.1); }
.white-popup .table_body tr td {
  color: #323c47;
  font-family: "Muli-SemiBold";
  font-size: 14px;
  line-height: 21px;
  vertical-align: middle;
  padding: 0 25px;
  width: 50%; }
.white-popup .wrap_reset {
  display: none; }
.white-popup .mfp-close {
  display: none; }
.white-popup h2 {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px;
  letter-spacing: -0.57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c5c9cd;
  padding: 20px 0 10px; }
.white-popup .wrap_link {
  display: flex;
  align-items: center;
  margin-top: 20px; }
@media (max-width: 767px) {
  .white-popup .wrap_link {
    flex-direction: column;
    align-items: flex-start; } }
.white-popup .wrap_link .btn {
  margin-right: 20px; }
@media (max-width: 767px) {
  .white-popup .wrap_link .btn {
    margin-right: 0;
    margin-bottom: 10px; } }
.white-popup .wrap_link .cansel {
  color: #808fa3;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  transition: .5s; }
.white-popup .wrap_link .cansel:hover {
  opacity: .7;
  transition: .5s; }
.white-popup#new_attribute {
  max-width: 563px; }
.white-popup#new_attribute .wrap_info .wrap_item:last-child {
  margin-bottom: 20px; }
.white-popup#new_attribute .wrap_info > .wrap_item {
  margin-bottom: 0; }
.white-popup#new_attribute .wrap_radio > label {
  margin-bottom: 20px; }
.white-popup#new_attribute .wrap_radio .wrap_item {
  margin-bottom: 10px; }
.white-popup#asign_attribute {
  max-width: 991px; }
.white-popup#asign_attribute h2.h2_no_border {
  border: none; }
.white-popup#add_credit {
  max-width: 618px;
  min-height: 100%; }
.white-popup#add_credit.pdf {
  position: static;
  transform: translate(0, 0); }
.white-popup#add_credit h2 {
  padding: 0 30px; }
.white-popup#add_credit .main_block {
  padding: 30px; }
.white-popup#add_credit .main_block .logo, .white-popup#add_credit .main_block h3 {
  margin-bottom: 30px; }
@media (max-width: 991px) {
  .white-popup#add_credit .main_block .logo, .white-popup#add_credit .main_block h3 {
    margin-bottom: 5px; } }
.white-popup#add_credit .main_block h3 {
  color: #233539;
  font-family: "Muli-SemiBold";
  font-size: 16px; }
.white-popup#add_credit .main_block p {
  color: #233539;
  font-family: "Muli-SemiBold";
  font-size: 12px;
  line-height: 18px; }
.white-popup#add_credit .main_block .left_block p {
  margin-bottom: 8px; }
.white-popup#add_credit .main_block .left_block a {
  color: #233539;
  font-family: "Muli-SemiBold";
  font-size: 12px;
  line-height: 18px; }
@media (max-width: 991px) {
  .white-popup#add_credit .main_block .left_block a {
    margin-bottom: 20px;
    display: inline-block; } }
.white-popup#add_credit .main_block .right_block {
  text-align: right; }
@media (max-width: 991px) {
  .white-popup#add_credit .main_block .right_block {
    text-align: left; } }
.white-popup#add_credit table.main {
  margin: 22px 0 12px 0;
  border-collapse: collapse; }
.white-popup#add_credit table.main td {
  height: 47px;
  color: #233539;
  font-family: "Muli-SemiBold";
  font-size: 10px;
  height: 69px;
  padding: 10px;
  border: 1px solid #e2e9ef; }
.white-popup#add_credit table.main thead td {
  background-color: rgba(235, 236, 237, 0.5);
  vertical-align: middle;
  letter-spacing: 0.09px; }
.white-popup#add_credit .total {
  margin-left: auto; }
.white-popup#add_credit .total tr {
  line-height: 20px; }
.white-popup#add_credit .total td:nth-child(1) {
  color: #323c47;
  font-family: "Muli-SemiBold";
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 12px;
  display: block; }
.white-popup#add_credit .total td:nth-child(2) {
  color: #233539;
  font-family: "Muli-Bold";
  font-size: 10px; }
.white-popup#add_credit .wrap_link {
  padding-bottom: 0; }
.white-popup#add_credit .wrap_link a {
  margin-right: 12px; }
.white-popup#add_credit .wrap_link a:last-child {
  margin-right: 0; }
@media (max-width: 767px) {
  .white-popup#add_credit .wrap_link a {
    margin-right: 0; } }
.white-popup#add_credit .wrap_link a:nth-child(1) {
  background: #3180e2 url("../images/print.svg") no-repeat 15px center;
  padding-left: 35px;
  padding-bottom: 3px; }
.white-popup#add_credit .wrap_link a:nth-child(2) {
  background: #3180e2 url("../images/pdf.svg") no-repeat 15px center;
  padding-left: 35px;
  padding-bottom: 3px; }
.white-popup#send_messages {
  max-width: 563px; }
.white-popup#send_messages textarea {
  width: 100%;
  height: 96px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  resize: none;
  padding: 15px; }
.white-popup#filter_no_active .add {
  background-color: rgba(35, 53, 57, 0.5);
  color: #ffffff; }
.white-popup#participant_id {
  max-width: 563px; }
.white-popup#participant_id .mfp-close {
  display: block; }
.white-popup#participant_id h2 span {
  color: rgba(35, 53, 57, 0.7);
  font-family: "Muli-SemiBold";
  font-size: 20px;
  letter-spacing: -0.57px;
  margin-left: 10px; }
.white-popup#participant_id p {
  color: var(--input-text-color);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 5px; }
.white-popup#participant_id p:last-child {
  margin-bottom: 0; }
.white-popup#participant_id p span {
  margin-left: 10px;
  color: #323c47; }
.white-popup.filter .apply {
  background: rgba(35, 53, 57, 0.5);
  color: #ffffff; }
.white-popup.filter .reset {
  border: 1px solid #757c84;
  background-color: #ffffff;
  color: #757c84; }
.white-popup.filter .wrap_select {
  display: flex; }
@media (max-width: 767px) {
  .white-popup.filter .wrap_select {
    flex-direction: column;
    align-items: flex-start; } }
.white-popup.filter .wrap_select select:nth-child(2) {
  margin: 0 15px 0 7px; }
@media (max-width: 767px) {
  .white-popup.filter .wrap_select select:nth-child(2) {
    margin: 10px 0; } }
.white-popup.filter .wrap_select .btn-primary {
  padding: 0 24px; }
.white-popup#delete h2 {
  background: url("../images/delete_account.svg") no-repeat 20px center;
  padding-left: 55px; }
.white-popup#delete .wrap_info .wrap_item {
  margin-bottom: 40px; }
.white-popup#delete .wrap_info .btn-primary {
  background-color: #e74f4f; }
.white-popup#configure .link_no_bg {
  border-radius: 4px;
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  max-width: 380px;
  word-break: break-all; }
.white-popup#configure .wrap_link {
  margin-top: 40px; }
.white-popup#edit {
  max-width: 564px; }
.white-popup#edit .link_bg {
  padding: 15px;
  border-radius: 4px;
  background-color: rgba(234, 237, 241, 0.5);
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px; }
.white-popup#edit .copy_link {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #307fe2;
  padding: 2px 10px 0 35px;
  display: block;
  color: #307fe2;
  font-family: Muli-Bold;
  display: inline-block;
  font-size: 14px;
  background: url("../images/copy_link.svg") no-repeat 10px center;
  margin: 15px 0 25px 0; }
.white-popup#edit .wrap_link {
  margin-top: 30px; }
.white-popup#edit .wrap_item {
  display: flex; }
.white-popup#edit .wrap_item:last-child {
  margin-bottom: 0; }
.white-popup#edit .wrap_item .item {
  width: 100%; }
.white-popup#edit .wrap_item .item:nth-child(odd) {
  margin-right: 20px; }
.white-popup#edit .wrap_item .item input {
  max-width: 252px; }
.white-popup .wrap_table {
  overflow: auto; }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
.mfp-preloader a {
  color: #CCC; }
.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
.mfp-close:hover, .mfp-close:focus {
  opacity: 1; }
.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
.mfp-arrow:active {
  margin-top: -54px; }
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1; }
.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }
.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }
.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.47
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  display: block;
  margin: 2px 0;
  padding: 4px;
  width: 19em; }

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute; }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px; }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px; }

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0; }

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0; }

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none; }

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0; }

.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM"; }

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker"; }

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today"; }

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center; }

.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens"; }

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit; }

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%; }

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0; }

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px; }

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px; }

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px; }

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month"; }

.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month"; }

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer; }

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee; }

.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px; }

.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777; }

.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px; }

.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer; }

.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777; }

.bootstrap-datetimepicker-widget table td.today {
  position: relative; }

.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff; }

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px; }

.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee; }

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.bootstrap-datetimepicker-widget table td span.old {
  color: #777777; }

.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px; }

.bootstrap-datetimepicker-widget.wider {
  width: 21em; }

.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important; }

.input-group.date .input-group-addon {
  cursor: pointer; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

button {
  -webkit-tap-highlight-color: transparent; }

input[type=button] {
  cursor: pointer;
  outline: none; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

li {
  list-style: none; }

body {
  min-width: 300px;
  position: relative;
  font-family: Muli; }

img {
  max-width: 100%;
  vertical-align: bottom;
  /*Что бы убрать странный отступ у картинки*/ }

video {
  vertical-align: bottom;
  /*Что бы убрать странный отступ у картинки*/ }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

@font-face {
  font-family: Muli;
  /* имя шрифта для CSS правил */
  src: local("Muli"), url(../fonts/Muli.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

@font-face {
  font-family: Muli-SemiBold;
  /* имя шрифта для CSS правил */
  src: local("Muli-SemiBold"), url(../fonts/Muli-SemiBold.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

@font-face {
  font-family: Muli-Bold;
  /* имя шрифта для CSS правил */
  src: local("Muli-Bold"), url(../fonts/Muli-Bold.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

@font-face {
  font-family: opensansbold;
  /* имя шрифта для CSS правил */
  src: local("opensansbold"), url(../fonts/opensansbold.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

@font-face {
  font-family: opensans;
  /* имя шрифта для CSS правил */
  src: local("opensans"), url(../fonts/opensans.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

@font-face {
  font-family: opensanssemibold;
  /* имя шрифта для CSS правил */
  src: local("opensanssemibold"), url(../fonts/opensanssemibold.ttf);
  /* если шрифт не установлен, тогда загружаем его по указанному пути */ }

.dropdown-menu {
  display: none; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/select_arrow.png") no-repeat;
  background-position: bottom 50% right 15px; }

.wrap_info {
  padding: 20px; }
.wrap_info .wrap_item {
  margin-bottom: 10px; }
.wrap_info label {
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  display: block; }
.wrap_info input, .wrap_info tags, .wrap_info select {
  max-width: 455px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  padding-left: 15px}
.wrap_info tags {
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto; }
.wrap_info tags input {
  height: auto;
  border: none;
  padding-left: 0; }
.wrap_info input, .wrap_info tags {
  max-width: 100%; }
.wrap_info input tag x:after, .wrap_info tags tag x:after {
  content: '';
  background: url("../images/tag_close.svg") no-repeat;
  width: 15px;
  height: 15px;
  display: block; }
.wrap_info input tag x:hover, .wrap_info tags tag x:hover {
  background: url("../images/tag_close.svg") no-repeat; }
.wrap_info input tag div, .wrap_info tags tag div {
  min-width: 53px;
  height: 30px;
  border-radius: 4px;
  background-color: #f0f4f7; }
.wrap_info input tag div span, .wrap_info tags tag div span {
  position: relative;
  top: -1px;
  margin-right: 15px; }
.wrap_info .info {
  display: block;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 10px; }

.menu_dashed {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  height: auto !important;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
  display: none; }
.menu_dashed li {
  margin-bottom: 10px; }
.menu_dashed li:last-child {
  margin-bottom: 0; }
.menu_dashed li .id_top {
  background: url("../images/man_dashed.svg") no-repeat left 5px; }
.menu_dashed li .view {
  background: url("../images/view_dashed.svg") no-repeat left 5px; }
.menu_dashed li .set {
  background: url("../images/set_dashed.svg") no-repeat left 5px; }
.menu_dashed li .give {
  background: url("../images/give_dashed.svg") no-repeat left 5px; }
.menu_dashed li .send {
  background: url("../images/send_dashed.svg") no-repeat left 5px; }
.menu_dashed li .reply {
  background: url("../images/reply_dashed.svg") no-repeat left 5px; }
.menu_dashed li:first-child {
  border-bottom: 1px dashed rgba(35, 53, 57, 0.5);
  padding-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px; }
.menu_dashed li a,
.menu_dashed li div {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  transition: .5s;
  padding-left: 25px; }
.menu_dashed li a:hover {
  opacity: .7;
  transition: .5s; }

.wrapper_main {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  margin: 30px 0; }

.wrap_radio {
  margin-bottom: 20px; }
.wrap_radio label {
  display: block;
  margin-bottom: 0; }
.wrap_radio label input[type="radio"] {
  display: none; }
.wrap_radio label input[type="radio"] ~ span {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px; }
.wrap_radio label input[type="radio"] ~ span:before {
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  background: #fff;
  margin-right: 5px;
  border: 1px solid #4a4a4a;
  border-radius: 50%;
  top: 2.5px;
  left: 0;
  background-color: #ffffff;
  background-clip: content-box;
  box-sizing: border-box;
  padding: 4px;
  cursor: pointer;
}

.wrap_radio label input[type="radio"]:checked ~ span:before {
  background-color: #149ead; }

.radio-toolbar {
  display: flex;
  flex-wrap: wrap; }
.radio-toolbar input[type="radio"], .radio-toolbar input[type="checkbox"] {
  display: none; }
.radio-toolbar label {
  height: 40px;
  border-radius: 4px;
  background-color: #eaedf1;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  padding: 0 20px 0 40px;
  margin-bottom: 0; }
.radio-toolbar label:last-child {
  margin-right: 0; }
.radio-toolbar #male {
  background: #eef1f4 url("../images/male.svg") no-repeat 15px center; }
.radio-toolbar #male :checked ~ * {
  background: #808fa3 url("../images/male_hover.svg") no-repeat 15px center; }
.radio-toolbar #female {
  background: #eef1f4 url("../images/female.svg") no-repeat 15px center; }
.radio-toolbar #female :checked ~ * {
  background: #808fa3 url("../images/female_hover.svg") no-repeat 15px center; }
.radio-toolbar #other {
  background: #eef1f4 url("../images/other.svg") no-repeat 15px center; }
.radio-toolbar #other :checked ~ * {
  background: #808fa3 url("../images/other_hover.svg") no-repeat 15px center; }
.radio-toolbar input[type="checkbox"] ~ * {
  color: #a5adb1; }
.radio-toolbar input[type="checkbox"]:checked ~ * {
  background-color: #808fa3;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 40px;
  margin-left: -40px;
  margin-right: -20px;
  border-radius: 4px;
  color: #ffffff; }

.radio-checkbox {
  display: flex; }
.radio-checkbox .wrap_checkbox_line {
  margin-right: 40px; }
.radio-checkbox .wrap_checkbox_line:last-child {
  margin-right: 0; }
.radio-checkbox .checkbox {
  display: none; }
.radio-checkbox .checkbox-custom {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #808fa3;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle; }
.radio-checkbox .label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  position: relative;
  top: -2px; }
@media (max-width: 479px) {
  .radio-checkbox .label {
    display: inline;
  } }
.radio-checkbox .checkbox:checked + .checkbox-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: url("../images/checkbox.svg") no-repeat center center;
  border-radius: 2px; }

.wrap_operation {
  justify-content: flex-end; }
.wrap_operation a {
  margin-right: 15px;
  transition: .5s; }
.wrap_operation a:hover {
  opacity: .7;
  transition: .5s; }
.wrap_operation a:last-child {
  margin-right: 0; }
.wrap_operation a:before {
  position: absolute;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 5px 10px;
  color: #323c47;
  font-family: "Muli-SemiBold";
  font-size: 12px;
  right: 20px;
  white-space: pre;
  top: 30px;
  transition: .5s;
  display: none;
  z-index: 9999; }
.wrap_operation a:hover:before {
  display: block;
  transition: .5s; }
.wrap_operation .edit {
  background: url("../images/edit.svg") no-repeat;
  width: 19px;
  height: 18px; }
.wrap_operation .add_people {
  background: url("../images/add_people.svg") no-repeat;
  width: 22px;
  height: 21px;
  position: relative; }
.wrap_operation .add_people:before {
  content: 'Assign attributes'; }
.wrap_operation .views {
  background: url("../images/views.svg") no-repeat;
  width: 23px;
  height: 16px;
  position: relative; }
.wrap_operation .views:before {
  content: 'View attributes'; }
.wrap_operation .download_option {
  background: url("../images/download_option.svg") no-repeat;
  width: 21px;
  height: 19px;
  position: relative; }
.wrap_operation .download_option:before {
  content: 'Download'; }
.wrap_operation .delete {
  background: url("../images/delete.svg") no-repeat;
  width: 17px;
  height: 16px; }

.form_style label {
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  display: block; }

.form_style input {
  width: 100%;
  max-width: 523px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #fff;
  padding-left: 20px !important; }

.form_style span {
  display: block;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 10px; }

.wrap_link {
  display: flex;
  align-items: center; }
@media (max-width: 767px) {
  .wrap_link {
    flex-direction: column;
    align-items: flex-start; } }
.wrap_link a,
.wrap_link button {
  margin-right: 15px; }
.wrap_link a:last-child {
  margin-right: 0; }
@media (max-width: 767px) {
  .wrap_link a,
  .wrap_link button {
    margin-bottom: 10px;
    margin-right: 0; }
  .wrap_link a:last-child {
    margin-bottom: 0; } }

.btn_cancel {
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #8a9395;
  color: #8a9395;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  margin-right: 15px; }
@media (max-width: 767px) {
  .btn_cancel {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%; } }
.btn_cancel:hover {
  transition: .5s;
  opacity: .6; }

.btn_save_later {
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #307fe2;
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: .5s; }
@media (max-width: 767px) {
  .btn_save_later {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%; } }
.btn_save_later:hover {
  transition: .5s;
  opacity: .6; }

.btn_save_run {
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  background-color: #307fe2;
  color: #ffffff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  border: 1px solid transparent; }
@media (max-width: 767px) {
  .btn_save_run {
    width: 100%; } }
.btn_save_run:hover {
  transition: .5s;
  background-color: transparent;
  border: 1px solid #307fe2;
  color: #307fe2; }

body, html {
  background: #f1f1f1;
  height: 100%; }

main {
  position: relative;
  min-height: 100%; }

main > .container {
  padding-bottom: 100px !important; }

.container {
  padding: 0 15px !important; }

.wrap_projects,
.panel-body,
.panel-success {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.panel-success .panel-body {
  box-shadow: none;
  background: none; }

.zmdi-chevron-down {
  color: #fff;
  font-size: 16px;
  margin-left: 8px;
  position: relative;
  top: 2px; }

.btn-primary {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  background-color: #3180e2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s; }
.btn-primary:focus {
  box-shadow: none !important;
}
.btn-primary:hover {
  background: #3180e2 !important;
  opacity: .7;
  transition: .5s; }

.navbar-default {
  box-shadow: 0 1px 6px transparent;
  background-color: #307fe2;
  margin-bottom: 20px; }
.navbar-default .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 69px; }
.navbar-default .navbar-header {
  display: flex;
  flex-shrink: 0; }
.navbar-default .navbar-header img {
  transition: .5s; }
.navbar-default .navbar-header img:hover {
  opacity: .7;
  transition: .5s; }
.navbar-default .navbar-nav {
  display: flex;
  align-items: center;
  padding-right: 30px; }
@media (max-width: 767px) {
  .navbar-default .navbar-nav {
    display: none;
    position: fixed;
    background: #fff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    padding-right: 0;
    padding: 50px; } }
.navbar-default .navbar-nav > li {
  margin-right: 30px;
  position: relative; }
.navbar-default .navbar-nav li:last-child {
  margin-right: 0; }
@media (max-width: 767px) {
  .navbar-default .navbar-nav li {
    margin-right: 0; } }
.navbar-default .navbar-nav li a {
  color: rgba(255, 255, 255, 0.8);
  font-family: Muli-Bold;
  font-size: 14px;
  transition: .5s; }
.navbar-default .navbar-nav li a.active {
  color: white;
  border-bottom: 4px solid rgba(255, 255, 255, 0.85);
  padding-bottom: 20px; }
@media (max-width: 767px) {
  .navbar-default .navbar-nav li a {
    color: #323c47;
    font-size: 20px;
    letter-spacing: -0.4px; } }
.navbar-default .navbar-nav li a:hover {
  transition: .5s;
  opacity: .7; }
.navbar-default .icon.ion-android-menu {
  font-size: 39px;
  color: #fff;
  display: none; }
.navbar-default .icon.ion-android-menu:hover {
  cursor: pointer; }
@media (max-width: 767px) {
  .navbar-default .icon.ion-android-menu {
    display: block; } }
@media (min-width: 320px) and (max-width: 479px) {
  .navbar-default .icon.ion-android-menu {
    margin-left: 138px;
  } }
.navbar-default .icon.ion-close-round {
  font-size: 31px;
  color: #323c47;
  display: none;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 99999; }
.navbar-default .icon.ion-close-round:hover {
  cursor: pointer; }

.user_block, .help_block {
  position: static;
  padding-left: 30px; }

@media (max-width: 991px) {
  .user_block, .help_block {
    padding-left: 0; } }
.user_block .wrap_img_user, .user_block .help, .help_block .wrap_img_user, .help_block .help {
  position: static;
  flex-shrink: 0;
  display: flex;
  align-items: center; }
.user_block .wrap_img_user .dropdown-menu, .user_block .help .dropdown-menu, .help_block .wrap_img_user .dropdown-menu, .help_block .help .dropdown-menu {
  position: absolute;
  top: 30px;
  right: 0;
  width: 240px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  z-index: 9999;
  padding: 11px; }
.user_block .wrap_img_user .dropdown-menu a, .user_block .help .dropdown-menu a, .help_block .wrap_img_user .dropdown-menu a, .help_block .help .dropdown-menu a,
.dropdown-menu li div {
  color: #323c47;
  font-family: Muli;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 34px;
  transition: .5s; }
.user_block .wrap_img_user .dropdown-menu a:hover, .user_block .help .dropdown-menu a:hover, .help_block .wrap_img_user .dropdown-menu a:hover, .help_block .help .dropdown-menu a:hover {
  transition: .5s;
  color: #e74f4f; }
.user_block:before, .help_block:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 25px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%); }
@media (max-width: 767px) {
  .user_block:before, .help_block:before {
    content: none; } }
.user_block a.dropdown-toggle, .help_block a.dropdown-toggle {
  font-family: Muli-SemiBold;
  color: #fff !important;
  font-size: 14px;
  transition: .5s; }
.user_block a.dropdown-toggle:hover, .help_block a.dropdown-toggle:hover {
  transition: .5s;
  opacity: .7; }

.help_block {
  padding-left: 0; }
.help_block:before {
  content: none; }
.help_block .search {
  margin-right: -11px !important;
  margin-left: -11px;
  margin-top: -11px; }
.help_block .search {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: rgba(226, 233, 239, 0.4);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px; }
.help_block .search label {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  margin-right: 10px; }
.help_block .search input {
  padding-left: 10px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  width: 100%; }

.task-list-header {
  margin-bottom: 20px; }
.task-list-header {
  position: relative; }
@media (max-width: 992px) {
  .task-list-header {
    margin-top: 15px; } }
.task-list-header .form-control {
  width: 276px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background: #ffffff;
  color: #808fa3;
  font-family: Muli;
  font-size: 14px;
  padding: 0 30px 0 15px;
  position: relative; }
@media (max-width: 991px) {
  .task-list-header .form-control {
    width: 100%;
    max-width: 100%; } }
@media (max-width: 767px) {
  .task-list-header .form-control {
    margin-left: 0; } }
.task-list-header .form-control:focus ~ .menu {
  display: block; }
.task-list-header input[type=submit] {
  position: absolute;
  right: 0;
  background: url("../images/search-icon.svg") no-repeat 95% center;
  border: none;
  outline: none;
  width: 17px;
  height: 17px;
  font-size: 0;
  margin-right: 10px;
  top: 50%;
  transform: translate(0, -50%); }
@media (max-width: 767px) {
  .task-list-header input[type=submit] {
    margin-right: 15px; } }
.task-list-header input[type=submit]:hover {
  cursor: pointer; }
.task-list-header .menu {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  padding: 10px 0;
  width: 100%;
  display: none; }
.task-list-header .menu .title {
  border-bottom: 1px dashed rgba(35, 53, 57, 0.5);
  padding-bottom: 10px;
  margin-bottom: 10px; }
.task-list-header .menu li {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 13px;
  margin-bottom: 5px;
  padding: 0 20px; }
.task-list-header .menu li:last-child {
  margin-bottom: 0; }
.task-list-header .wrap_btn {
  position: relative; }
.task-list-header .wrap_btn .menu_run {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  padding: 12px;
  width: 100%;
  display: none; }
.task-list-header .wrap_btn .menu_run li a {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  margin-bottom: 10px;
  transition: .5s; }
.task-list-header .wrap_btn .menu_run li:last-child {
  margin-bottom: 0; }
.task-list-header .wrap_btn .menu_run li:hover {
  opacity: .7;
  transition: .5s; }
.task-list-header .wrap_btn .menu_run li:nth-child(2) {
  padding-left: 20px;
  background: url("../images/menu_run_1.svg") no-repeat left center; }
.task-list-header .wrap_btn .menu_run a.title {
  display: flex;
  align-items: center;
  color: rgba(35, 53, 57, 0.8);
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px; }
.task-list-header .wrap_btn .menu_run a.title .zmdi {
  margin-left: 5px; }
.task-list-header .wrap_btn .btn-primary {
  margin-right: 15px;
  position: relative;
  z-index: 99; }
@media (max-width: 767px) {
  .task-list-header .wrap_btn .btn-primary {
    margin-right: 0;
    margin-bottom: 15px; } }
.task-list-header .wrap_btn .btn-primary:hover .menu_run {
  display: block; }

.wrap_projects, .wrap_link .btn.btn-default {
  background: #fff; }
.wrap_projects .checkbox input[type=checkbox] {
  display: none; }
.wrap_projects .checkbox input[type=checkbox]:checked + label:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  color: #f3f3f3;
  text-align: center;
  line-height: 15px; }
.wrap_projects .checkbox label:before {
  border-radius: 3px; }
.wrap_projects .feedback .item {
  padding: 15px 20px;
  height: auto; }
.wrap_projects .feedback .item div {
  height: auto; }
.wrap_projects .feedback .item .blue {
  color: #548dcd; }
.wrap_projects .feedback .item .red {
  color: #e74f4f; }
.wrap_projects .feedback .item p {
  margin-bottom: 5px; }
.wrap_projects .feedback .item p:last-child {
  margin-bottom: 0; }
.wrap_projects .top_row div {
  font-family: Muli-Bold;
  color: #233539; }
.wrap_projects.platform p {
  margin-bottom: 0 !important; }
.wrap_projects.platform .color_1 {
  color: #5cc74d;
  position: relative;
  padding-left: 15px; }
.wrap_projects.platform .color_1:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0;
  top: 7px;
  background-color: #5bc74d;
  border-radius: 50%; }
.wrap_projects.platform .color_2 {
  color: #808fa3; }
.wrap_projects .list-heading {
  color: #233539;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  background-color: rgba(235, 236, 237, 0.5);
  box-shadow: 0 1px 0 #e9eff4; }
.wrap_projects .color {
  color: #7ab772; }
@media (max-width: 991px) {
  .wrap_projects .list-heading {
    display: none !important; } }
.wrap_projects .list-item {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  font-family: Muli-SemiBold;
  color: #323c47;
  font-size: 14px;
  box-shadow: 0 1px 0 #e9eff4; }
.wrap_projects .list-item span.text {
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-left: 40px; }
@media (max-width: 991px) {
  .wrap_projects .list-item {
    height: auto;
    position: relative;
    padding: 20px 20px 20px 20px;
    flex-direction: column; } }


@media (max-width: 991px) {
  .wrap_projects .list-item div {
    max-width: 100%;
    text-align: left !important;
    display: block;
    font-family: Muli-SemiBold;
    color: var(--input-text-color);
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
footer .copyright {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px; }
@media (max-width: 991px) {
  footer .copyright {
    margin-bottom: 45px; } }
footer .chat {
  position: absolute;
  right: 0;
  bottom: 0;
  background: url("../images/chat.svg") no-repeat;
  width: 123px;
  height: 37px;
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  padding-left: 8px; }
footer .chat:hover {
  opacity: .7;
  cursor: pointer;
  transition: .5s; }
footer .online_chat {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 271px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  z-index: 999;
  display: none; }
footer .online_chat .header {
  min-height: 50px;
  background-color: #307fe2;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
footer .online_chat .header p {
  color: #ffffff;
  font-family: Muli-Bold;
  font-size: 14px; }
footer .online_chat .header .cut {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: rgba(50, 60, 71, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }
footer .online_chat .header .cut .line {
  width: 11px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.7); }
footer .online_chat .window {
  padding: 15px;
  height: 263px;
  overflow-y: scroll;
  margin-bottom: 50px; }
footer .online_chat .window .message {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start; }
footer .online_chat .window .message:last-child {
  margin-bottom: 0; }
footer .online_chat .window .message img {
  width: 28px;
  display: block;
  margin-right: 10px;
  position: relative;
  top: -5px; }
footer .online_chat .window .message p {
  color: #323c47;
  font-family: Muli;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px 4px 4px 2px;
  padding: 10px; }
footer .online_chat .window .message.robot p {
  background-color: rgba(117, 124, 132, 0.05);
  position: relative;
  margin-left: 15px; }
footer .online_chat .window .message.robot p:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 15px 0;
  border-color: transparent rgba(117, 124, 132, 0.05) transparent transparent;
  top: 0;
  left: -25px; }
footer .online_chat .window .message.user {
  flex-direction: row-reverse; }
footer .online_chat .window .message.user img {
  margin-right: 0;
  margin-left: 10px; }
footer .online_chat .window .message.user p {
  background-color: rgba(117, 124, 132, 0.05);
  position: relative;
  margin-right: 15px; }
footer .online_chat .window .message.user p:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 25px 0 0;
  border-color: rgba(117, 124, 132, 0.05) transparent transparent transparent;
  top: 0;
  right: -25px; }
footer .online_chat .window .message_line {
  padding: 15px;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  border-top: 1px solid rgba(117, 124, 132, 0.3);
  border-left: none;
  border-right: none;
  border-bottom: none;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 50px; }

/*
.panel-body {
  padding: 20px;
  background: #fff;
  &.participants,
  &.project_detail {
    padding-top: 0;
  }
}
*/
.task-list-header .btn.btn-default, .wrap_link .btn.btn-default {
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #548dcd;
  display: inline-flex;
  align-items: center;
  color: #548dcd;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  transition: .5s; }
.task-list-header .btn.btn-default:hover, .wrap_link .btn.btn-default:hover {
  background: #548dcd;
  color: #fff;
  transition: .5s;
  border: 1px solid transparent; }

.panel.panel-success {
  /*
    margin: 15px 15px 0 0;
    background: #fff;
    @include media-breakpoint-down(xs) {
      margin-right: 0;
    }
    h2 {
      color: #233539;
      font-family: Muli-Bold;
      font-size: 20px;
      letter-spacing: -0.57px;
      border-bottom: 1px solid #808fa3;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }*/ }
.panel.panel-success .pull-right a {
  background: url("../images/pull-right.svg") no-repeat;
  width: 26px;
  height: 25px;
  display: block;
  transform: rotate(-90deg); }
.panel.panel-success .form-group {
  margin-bottom: 20px; }
.panel.panel-success p,
.po-p {
  font-family: Muli-SemiBold;
  color: var(--input-text-color);
  font-size: 14px;
  letter-spacing: -0.4px; }
.panel.panel-success label,
.po-label {
  color: #323c47;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-Bold;
  margin-bottom: 4px;
}
.panel.panel-success label {
  display: inline-block;
}
.panel.panel-success a {
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: -0.4px;
  font-family: Muli-SemiBold;
  color: #4da1ff;
  word-wrap: break-word; }
.panel.panel-success h4 {
  color: #323c47;
  font-size: 16px;
  letter-spacing: -0.46px;
  margin-bottom: 10px;
  font-family: Muli-Bold; }
.panel.panel-success h4 + ul li {
  color: #323c47;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  font-family: Muli-SemiBold; }
.panel.panel-success h4 + ul li:last-child {
  margin-bottom: 0; }
.panel.panel-success h4 + ul li .ion-android-checkbox {
  color: #808fa3;
  font-size: 16px;
  margin-right: 5px; }
.panel.panel-success .panel-heading.filters {
  width: 100%;
  /*h3 {
      margin: 20px 0;
      color: #233539;
      font-size: 20px;
      letter-spacing: -0.57px;
      font-family: Muli-Bold;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        border-top: 1px solid #e2e9ef;
        width: 100%;
        left: 0;
        top: -20px;
        margin-left: -20px;
        padding-right: 40px;
      }
    }*/ }
@media (max-width: 991px) {
  .panel.panel-success .table-bordered {
    margin-bottom: 20px; } }
.panel.panel-success .table-bordered tr {
  margin-bottom: 10px;
  display: block; }
.panel.panel-success .table-bordered tr:last-child {
  margin-bottom: 0; }
.panel.panel-success .table-bordered tr td:first-child {
  color: #323c47;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-Bold;
  display: inline; }
.panel.panel-success .table-bordered tr td:last-child {
  color: #5cc74d;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-SemiBold;
  margin-left: 10px;
  display: inline-block; }
.panel.panel-success .end-of-task-url #foo {
  position: relative; }
.panel.panel-success .end-of-task-url button.btn {
  position: absolute;
  right: 12px;
  top: 46%;
  transform: translate(0, -50%);
  border: none;
  background: none;
  cursor: pointer; }
.panel.panel-success .end-of-task-url label.text-danger {
  display: block;
  color: #e74f4f;
  font-family: Muli;
  font-size: 14px;
  letter-spacing: -0.4px; }
.panel.panel-success .end-of-task-url #task-feedback-url {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  padding-left: 20px;
  color: var(--input-text-color);
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-SemiBold;
  margin: 5px 0 10px 0; }

.panel.panel-default {
  margin-top: 15px;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-Bold; }
.panel.panel-default p {
  margin-bottom: 15px; }
.panel.panel-default p:last-child {
  margin-bottom: 0; }
.panel.panel-default label {
  color: #323c47;
  margin-right: 10px;
  display: inline-block; }
.panel.panel-default .row.vertical-space-md {
  align-items: center;
  margin-bottom: 20px; }
.panel.panel-default .row.vertical-space-md .clear-margin {
  color: #233539;
  font-size: 20px;
  letter-spacing: -0.57px;
  font-family: Muli-Bold; }
@media (max-width: 767px) {
  .panel.panel-default .row.vertical-space-md .clear-margin {
    text-align: left;
    margin-top: 15px; } }

.vertical-space-md .btn.btn-primary {
  color: #ffffff !important;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  background-color: #307fe2;
  transition: .5s; }
.vertical-space-md .btn.btn-primary:hover {
  background: none;
  color: #4da1ff !important;
  border: 1px solid #4da1ff;
  transition: .5s; }

.vertical-space-md .alert-danger {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px;
  letter-spacing: -0.57px; }
app/lib/positly/config.rb
.runlist .list-item.list-heading {
  background: #f5f5f6;
  /* margin-left: -20px;
     padding-left: 20px;
     margin-right: -20px;
     padding-right: 20px;*/ }
@media (max-width: 991px) {
  .runlist .list-item.list-heading {
   display: none; } }

.runlist .wrap_box {
  width: 100%; }

.runlist .list-item {
  min-height: 60px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: Muli-Bold;
  color: #233539; }
@media (max-width: 991px) {
  .runlist div:not(.menu_dashed):not(.wrap_text):not(.infinite-scroll-component__outerdiv):not(.infinite-scroll-component) {
    height: auto;
    max-width: 100%;
  } }

@media (max-width: 991px) {
  .list-item {
    padding: 20px 0; } }

@media (max-width: 991px) {
  .list-item > div:not(.row) {
    margin-bottom: 10px; }

  .list-item .row > div {
    margin-bottom: 10px; }

  .list-item > div:last-child {
    margin-bottom: 0; } }

.list-item > div.action .menu_hover {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 180px;
  height: auto;
  padding: 20px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  z-index: 9999;
  display: none; }
@media (max-width: 991px) {
  .list-item > div.action .menu_hover {
    right: auto;
    left: 15px;
    top: 0; } }
.list-item > div.action .menu_hover.menu_hover_2 li:nth-child(1) a {
  background: url("../images/menu_h_5.svg") no-repeat left center; }
.list-item > div.action .menu_hover a {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 30px;
  padding-left: 33px;
  transition: .5s; }
.list-item > div.action .menu_hover a:hover {
  transition: .5s;
  opacity: .7; }
.list-item > div.action .menu_hover li:nth-child(1) a {
  background: url("../images/menu_h_1.svg") no-repeat left center; }
.list-item > div.action .menu_hover li:nth-child(2) a {
  background: url("../images/menu_h_2.svg") no-repeat left center; }
.list-item > div.action .menu_hover li:nth-child(3) a {
  background: url("../images/menu_h_3.svg") no-repeat left center; }
.list-item > div.action .menu_hover li:nth-child(4) a {
  background: url("../images/menu_h_4.svg") no-repeat left center; }

.list-item .completed {
  display: flex; }

.circle_table.p100 img {
  margin-right: 10px;
  display: block; }

#circle, #circle_2 {
  margin-right: 10px; }

.col-md-2.status {
  flex-direction: column; }

.ion-android-arrow-back {
  margin-right: 10px; }

#dropdownMenu1 {
  background: url("../images/options.svg") no-repeat;
  width: 5px;
  height: 20px;
  border: none;
  outline: none;
  transition: .5s; }

#dropdownMenu1:hover {
  cursor: pointer;
  opacity: .7;
  transition: .5s; }

.status_style {
  justify-content: space-between; }

.running_color {
  color: #233539; }

.paused_color {
  color: #808fa3; }

.paused_due_complaints_color {
  color: #D97E7E; }

.hung_color {
  color: #548dcd; }

.retry_exhausted_color {
  color: #5c6069; }

.locked_color {
  color: #e74f4f; }

.publishing_color {
  color: #7AB772; }

.finished_color {
  color: #5cc74d !important; }

.pending_color p {
  color: var(--input-text-color); }

.status_bar {
  position: relative;
  display: flex;
  justify-content: space-around; }
.status_bar:before {
  position: absolute;
  content: '';
  width: 75%;
  height: 2px;
  background-color: #8a9395;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0); }
.status_bar .circle {
  width: 24px;
  height: 24px;
  border: 2px solid rgba(35, 53, 57, 0.5);
  background-color: #f1f1f1;
  border-radius: 50%;
  z-index: 100; }
.status_bar .step_1, .status_bar .step_2, .status_bar .step_3, .status_bar .step_4 {
  position: relative; }
.status_bar .step_1:before, .status_bar .step_2:before, .status_bar .step_3:before, .status_bar .step_4:before {
  position: absolute;
  content: '';
  width: 43px;
  height: 24px;
  background-color: #f1f1f1;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  overflow: hidden;
  z-index: 1; }
.status_bar .item {
  display: flex;
  flex-direction: column;
  align-items: center; }
.status_bar .item.active .circle {
  border: 3px solid #5bc74d; }
.status_bar .item.active p {
  color: #233539; }
.status_bar .item.passed .circle {
  background: url("../images/passed.svg") no-repeat;
  border: none; }
.status_bar .item.passed p {
  color: #233539; }
.status_bar .item p {
  margin-top: 5px;
  color: #8a9395;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px; }

.metrics, .top_line {
  margin: 30px 0; }
.metrics .date, .top_line .date {
  position: relative; }
.metrics .date .glyphicon-calendar, .top_line .date .glyphicon-calendar {
  background: url("../images/calendar.svg") no-repeat;
  display: block;
  width: 20px;
  height: 18px;
  position: absolute;
  right: 15px;
  top: 12px; }
@media (max-width: 991px) {
  .metrics .date input[type=text], .metrics .date .glyphicon-calendar, .top_line .date input[type=text], .top_line .date .glyphicon-calendar {
    display: none; } }
.metrics .date input[type=datetime-local], .top_line .date input[type=datetime-local] {
  display: none; }
@media (max-width: 991px) {
  .metrics .date input[type=datetime-local], .top_line .date input[type=datetime-local] {
    display: block; } }
.metrics h3, .top_line h3 {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 16px;
  letter-spacing: -0.46px;
  margin: 30px 0 10px 0; }
.metrics .list li, .top_line .list li {
  color: rgba(35, 53, 57, 0.7);
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.4px;
  padding-left: 20px;
  position: relative; }
.metrics .list li:before, .top_line .list li:before {
  content: '-';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%); }
.metrics .wrap_link_filter, .top_line .wrap_link_filter {
  margin-top: 30px; }
.metrics.activity .left .pay_with .wrap_input_text, .top_line.activity .left .pay_with .wrap_input_text {
  justify-content: space-between;
  margin-bottom: 15px; }
.metrics.activity .left .pay_with .wrap_input_text .copy_link, .top_line.activity .left .pay_with .wrap_input_text .copy_link {
  background: url("../images/configure.svg") no-repeat 10px center; }
.metrics.activity .left .pay_with .wrap_text, .top_line.activity .left .pay_with .wrap_text {
  margin-bottom: 8px; }
.metrics.activity .left .pay_with .wrap_text:last-child, .top_line.activity .left .pay_with .wrap_text:last-child {
  margin-bottom: 0; }
.metrics.activity .left .pay_with .wrap_text .title, .top_line.activity .left .pay_with .wrap_text .title {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 16px; }
.metrics.activity .left .pay_with .wrap_text .text, .top_line.activity .left .pay_with .wrap_text .text {
  color: #6c747c;
  font-family: Muli-SemiBold;
  font-size: 14px; }
.metrics.activity .left .integration input, .top_line.activity .left .integration input,
.completion_link_input {
  background: #eaedf1 url("../images/link_done.svg") no-repeat 7px center;
  padding: 0 30px; }
.metrics.activity .left .radio-checkbox, .top_line.activity .left .radio-checkbox {
  display: flex;
  margin: 10px 0;
  flex-direction: column; }
.metrics.activity .left .radio-checkbox label, .top_line.activity .left .radio-checkbox label {
  display: flex; }
.metrics.activity .left .radio-checkbox label:last-child, .top_line.activity .left .radio-checkbox label:last-child {
  margin-bottom: 0; }
.metrics.activity .left input, .metrics.activity .left textarea, .top_line.activity .left input, .top_line.activity .left textarea {
  margin-bottom: 8px; }
.metrics.activity .left .wrap_input_text, .top_line.activity .left .wrap_input_text,
.copy_completion_link_wrapper {
  display: flex; }
.metrics.activity .left .wrap_input_text input, .top_line.activity .left .wrap_input_text input,
.copy_completion_link_wrapper input.correct_link {
  max-width: 410px;
  height: 30px;
  border-radius: 4px;
  background-color: rgba(234, 237, 241, 0.5);
  margin-right: 5px; }
.metrics.activity .left .wrap_input_text a, .top_line.activity .left .wrap_input_text a,
.copy_code,
.copy_link,
.btn-small-open {
  height: 30px;
  border-radius: 4px;
  border: 1px solid #307fe2;
  padding: 2px 10px 0 35px;
  display: block;
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  background: url("../images/configure.svg") no-repeat 10px center;
  flex-shrink: 0;
  max-width: fit-content;
}
.metrics.activity .left .wrap_input_text a.copy_link, .top_line.activity .left .wrap_input_text a.copy_link,
.copy_link {
  background: url("../images/copy_link.svg") no-repeat 10px center; }
.metrics.activity .left .wrap_input_text a.copy_code, .top_line.activity .left .wrap_input_text a.copy_code,
.copy_code{
  background: url("../images/code.png") no-repeat 10px center;
  margin-left: 7px; }
.metrics.activity .left .box h2, .top_line.activity .left .box h2 {
  margin-top: 10px; }
.metrics.activity .left .box h2:first-child, .top_line.activity .left .box h2:first-child {
  margin-top: 0; }
.metrics.activity .left .box .link, .top_line.activity .left .box .link, .link {
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  transition: .5s; }
.metrics.activity .left .box .link:hover, .top_line.activity .left .box .link:hover,
.link:hover {
  opacity: .6;
  transition: .5s; }
.metrics.activity .left .box textarea, .top_line.activity .left .box textarea,
.contact-us-box textarea, .nps-reason textarea {
  width: 100%;
  height: 93px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  resize: none;
  padding: 20px;
  font-family: Muli-SemiBold; }
.metrics.activity .left .box .wrap_info_form, .top_line.activity .left .box .wrap_info_form {
  display: flex;
  justify-content: space-between; }
.metrics.activity .left .box .wrap_info_form span, .top_line.activity .left .box .wrap_info_form span,
.warning {
  color: rgba(51, 61, 72, 0.7);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px; }
.metrics.activity .left .box .wrap_info_form span.bg,
.top_line.activity .left .box .wrap_info_form span.bg,
.warning {
  background: url("../images/notice.svg") no-repeat left center;
  padding-left: 22px; }
.metrics.participants .box h2.bg, .top_line.participants .box h2.bg {
  background: url("../images/question.svg") no-repeat right center;
  display: inline-block;
  padding-right: 25px; }
.metrics.participants .box.gender .wrap_item, .top_line.participants .box.gender .wrap_item {
  margin-bottom: 0; }
.metrics.participants .box.gender .wrap_item label, .top_line.participants .box.gender .wrap_item label {
  margin-bottom: 0; }
.metrics.participants .box.education, .top_line.participants .box.education {
  padding-bottom: 5px; }
.metrics.participants .box.education label, .top_line.participants .box.education label {
  padding: 0 15px;
  margin-bottom: 15px !important; }
.metrics.participants .box.education input[type="checkbox"]:checked ~ *, .top_line.participants .box.education input[type="checkbox"]:checked ~ * {
  padding: 0 15px;
  margin-left: -15px;
  margin-right: -15px; }
.metrics.participants .box.select select, .top_line.participants .box.select select {
  max-width: 100%;
  margin-bottom: 20px; }
.metrics.participants .box.select a, .top_line.participants .box.select a {
  display: inline-flex; }
.metrics.participants .box.age h2, .top_line.participants .box.age h2 {
  font-size: 14px;
  text-transform: none;
  margin-bottom: 4px !important; }
.metrics.participants .box.age .wrap_left, .top_line.participants .box.age .wrap_left {
  width: auto;
  margin-right: 36px !important; }
.metrics.participants .box.age .wrap_left, .top_line.participants .box.age .wrap_left {
  position: relative; }
.metrics.participants .box.age .wrap_left:after, .top_line.participants .box.age .wrap_left:after {
  content: '-';
  position: absolute;
  right: -21px;
  top: 64%;
  transform: translate(0, -50%); }
.metrics.participants .box.age input {
  width: 70px; }
.top_line.participants .box.age input {
  width: 70px;
  padding-left: 0;
  text-align: center; }
.metrics .wrapper_link, .top_line .wrapper_link {
  margin-top: 30px; }
@media (max-width: 991px) {
  .metrics.part .form-control, .top_line.part .form-control {
    margin-left: 0; } }
@media (max-width: 991px) {
  .metrics.part input[type=submit], .top_line.part input[type=submit] {
    margin-right: 10px !important; } }
@media (max-width: 991px) {
  .metrics .wrap_btn, .top_line .wrap_btn {
    justify-content: flex-start !important;
    margin-top: 30px; } }
@media (max-width: 767px) {
  .metrics .wrap_btn, .top_line .wrap_btn {
    flex-direction: column; } }
.metrics.filter h1, .top_line.filter h1 {
  color: rgba(35, 53, 57, 0.7); }
.metrics.filter h1 span, .top_line.filter h1 span {
  color: #233539; }
.metrics.filter .ion-close, .top_line.filter .ion-close {
  position: absolute;
  top: 15px;
  right: 20px;
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 500;
  transition: .5s; }
.metrics.filter .ion-close:hover, .top_line.filter .ion-close:hover {
  cursor: pointer;
  opacity: .7;
  transition: .5s; }
.metrics.filter .wrap_form, .top_line.filter .wrap_form {
  width: 100%;
  margin-right: 20px; }
.metrics.filter .wrap_form, .top_line.filter .wrap_form {
  margin-right: 0; }
.metrics.filter .wrap_form #status, .top_line.filter .wrap_form  #status {
  width: 300px; }
.metrics.filter .wrap_form .add, .top_line.filter .wrap_form .add {
  color: #fff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  border: none;
  outline: none;
  width: 69px;
  height: 40px;
  border-radius: 4px;
  background-color: #307fe2;
  margin-left: 20px;
  transition: .5s; }
.metrics.filter .wrap_form .add:hover, .top_line.filter .wrap_form .add:hover {
  background-color: #fff;
  border: 1px solid #307fe2;
  color: #307fe2;
  transition: .5s;
  cursor: pointer; }
.metrics.filter .wrap_select, .top_line.filter .wrap_select {
  position: relative;
  max-width: 523px; }
.metrics.filter .wrap_select .zmdi, .top_line.filter .wrap_select .zmdi {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%); }
.metrics.filter .matching, .top_line.filter .matching {
  color: #31708f;
  font-size: 16px;
  font-family: Muli-Bold; }
.metrics.filter .checkbox, .top_line.filter .checkbox {
  margin-bottom: 35px; }
.metrics.filter .wrap_link_filter i, .top_line.filter .wrap_link_filter i {
  margin-left: 8px; }
.metrics.filter .wrap_link_filter i.ion-android-arrow-back, .top_line.filter .wrap_link_filter i.ion-android-arrow-back {
  margin-left: 0; }
.metrics.filter .wrap_link_filter .back,
.metrics.filter .wrap_link_filter .pricing,
.top_line.filter .wrap_link_filter .back,
.top_line.filter .wrap_link_filter .pricing,
.back {
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #548dcd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #548dcd;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  transition: .5s;
  margin-right: 15px; }
.metrics.filter .wrap_link_filter .back:hover, .metrics.filter .wrap_link_filter .pricing:hover, .top_line.filter .wrap_link_filter .back:hover, .top_line.filter .wrap_link_filter .pricing:hover {
  transition: .5s;
  background: #548dcd;
  color: #fff; }
.metrics.filter .wrap_link_filter .back.cancel, .metrics.filter .wrap_link_filter .pricing.cancel, .top_line.filter .wrap_link_filter .back.cancel, .top_line.filter .wrap_link_filter .pricing.cancel {
  color: #979ea0;
  border: 1px solid #979ea0; }
.metrics.filter .wrap_link_filter .back.cancel:hover, .metrics.filter .wrap_link_filter .pricing.cancel:hover, .top_line.filter .wrap_link_filter .back.cancel:hover, .top_line.filter .wrap_link_filter .pricing.cancel:hover {
  color: #fff;
  border: 1px solid #548dcd; }
.metrics.filter .wrap_link_filter .pricing, .top_line.filter .wrap_link_filter .pricing {
  width: 95px;
  margin-right: 0;
  background: #317fe2;
  color: #fff;
  position: relative;
  justify-content: flex-start;
  padding-left: 15px; }
.metrics.filter .wrap_link_filter .pricing:hover, .top_line.filter .wrap_link_filter .pricing:hover {
  background: #fff;
  color: #317fe2; }
.metrics.filter .box, .top_line.filter .box, .box {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;
  position: relative; }
.metrics.filter .box:last-child, .top_line.filter .box:last-child {
  margin-bottom: 0; }
.metrics.filter .box .wrap_form, .top_line.filter .box .wrap_form {
  display: flex;
  margin-top: 20px; }
@media (max-width: 767px) {
  .metrics.filter .box .wrap_form, .top_line.filter .box .wrap_form {
    flex-direction: column; } }
.metrics.filter .box .wrap_form > div, .top_line.filter .box .wrap_form > div {
  width: 100%; }
.metrics.filter .box .wrap_form .wrap_left, .top_line.filter .box .wrap_form .wrap_left {
  margin-right: 21px; }
@media (max-width: 767px) {
  .metrics.filter .box .wrap_form .wrap_left, .top_line.filter .box .wrap_form .wrap_left {
    margin-right: 0;
    margin-bottom: 20px; } }
.metrics.filter .box h2, .top_line.filter .box h2 {
  display: block;
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.7px;
  margin-bottom: 10px; }
@media (max-width: 1199px) {
  .metrics.filter .box h2, .top_line.filter .box h2 {
    letter-spacing: -0.9px; } }
.metrics.filter .box h2.big, .top_line.filter .box h2.big {
  font-size: 20px; }
@media (max-width: 1199px) {
  .metrics.filter .box h2.big, .top_line.filter .box h2.big {
    font-size: 15px; } }
.metrics.filter .box .wrap_input, .top_line.filter .box .wrap_input {
  margin-right: 15px;
  width: 92px;
  height: 40px; }
.metrics.filter .box .wrap_input:last-child, .top_line.filter .box .wrap_input:last-child {
  margin-right: 0; }
.metrics.filter .box .radio, .top_line.filter .box .radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px; }
.metrics.filter .box .radio + label, .top_line.filter .box .radio + label {
  position: relative;
  cursor: pointer; }
.metrics.filter .box .radio + label:before, .top_line.filter .box .radio + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 92px;
  height: 40px;
  border-radius: 4px;
  background-color: #eaecf0; }
.metrics.filter .box .radio + label:after, .top_line.filter .box .radio + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 92px;
  height: 40px;
  border-radius: 4px;
  background-color: #7f8ea3;
  opacity: 0;
  transition: .2s; }
.metrics.filter .box .radio:checked + label:after, .top_line.filter .box .radio:checked + label:after {
  opacity: 1; }
.metrics.filter .box select, .metrics.filter .box input, .metrics.filter .box textarea, .top_line.filter .box select, .top_line.filter .box input,
.form_style input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #fff;
  appearance: none;
  padding-left: 20px;
  color: var(--input-text-color);
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-SemiBold; }
@media (max-width: 1199px) {
  .metrics.filter .box select, .metrics.filter .box input, .metrics.filter .box textarea, .top_line.filter .box select, .top_line.filter .box input, .form_style input {
    font-size: 12px; } }
.metrics.filter .box select, .top_line.filter .box select {
  max-width: 251px; }
.metrics.filter .box .wrap_link_filter span, .top_line.filter .box .wrap_link_filter span {
  color: var(--input-text-color);
  font-family: Muli;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-right: 15px; }
.metrics.filter .box .wrap_link_filter a, .top_line.filter .box .wrap_link_filter a {
  color: #307fe2;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-right: 15px;
  border-bottom: 1px dashed #307fe2;
  transition: .5s; }
.metrics.filter .box .wrap_link_filter a:hover, .top_line.filter .box .wrap_link_filter a:hover {
  opacity: .7;
  transition: .5s; }
.metrics.filter .box .wrap_link_filter a:last-child, .top_line.filter .box .wrap_link_filter a:last-child {
  margin-right: 0; }
.metrics.filter .box.previous_runs .wrap_select, .top_line.filter .box.previous_runs .wrap_select {
  margin-bottom: 20px; }
.metrics.filter .box.previous_runs span, .top_line.filter .box.previous_runs span {
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px; }
.metrics.filter .box.previous_runs input, .top_line.filter .box.previous_runs input {
  min-width: auto; }
.metrics.filter .box.previous_participation h2, .metrics.filter .box.age h2, .metrics.filter .box.gender h2, .metrics.filter .box.previous_runs h2, .top_line.filter .box.previous_participation h2, .top_line.filter .box.age h2, .top_line.filter .box.gender h2, .top_line.filter .box.previous_runs h2 {
  font-size: 20px;
  margin-bottom: 20px; }
.metrics.filter .box.previous_participation .info, .metrics.filter .box.age .info, .metrics.filter .box.gender .info, .metrics.filter .box.previous_runs .info, .top_line.filter .box.previous_participation .info, .top_line.filter .box.age .info, .top_line.filter .box.gender .info, .top_line.filter .box.previous_runs .info {
  color: #e74f4f;
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 5px; }
.metrics.project_detail .wrap_title, .top_line.project_detail .wrap_title {
  margin: 20px 0 25px 0;
  justify-content: space-between; }
.metrics.project_detail .wrap_title h1, .top_line.project_detail .wrap_title h1 {
  margin: 0; }
.metrics.project_detail .wrap_box .box, .top_line.project_detail .wrap_box .box {
  width: 100%;
  height: 94px;
  position: relative; }
.metrics.project_detail .wrap_box .box:hover .hint, .top_line.project_detail .wrap_box .box:hover .hint {
  display: block; }
.metrics.project_detail .wrap_box .box .number, .top_line.project_detail .wrap_box .box .number {
  font-size: 35px; }
.metrics.project_detail .wrap_box .box .title, .top_line.project_detail .wrap_box .box .title {
  font-size: 16px; }
.metrics.project_detail .wrap_box .box .number.strong_green, .top_line.project_detail .wrap_box .box .number.strong_green {
  color: #5dba05; }
@media (max-width: 1199px) {
  .metrics.project_detail .wrap_box .box, .top_line.project_detail .wrap_box .box {
    width: 221px; }
  .metrics.project_detail .wrap_box .box:nth-child(-n+3), .top_line.project_detail .wrap_box .box:nth-child(-n+3) {
    margin-right: 15px; } }
.metrics.project_detail .wrap_box .box:nth-of-type(3), .top_line.project_detail .wrap_box .box:nth-of-type(3) {
  margin-bottom: 0; }
@media (max-width: 767px) {
  .metrics.project_detail .wrap_box .box, .top_line.project_detail .wrap_box .box {
    margin-right: 0 !important;
    width: 100%; }
  .metrics.project_detail .wrap_box .box:nth-child(even), .top_line.project_detail .wrap_box .box:nth-child(even) {
    margin-right: 0; }
  .metrics.project_detail .wrap_box .box:nth-of-type(3), .top_line.project_detail .wrap_box .box:nth-of-type(3) {
    margin-bottom: 15px; } }
.metrics.project_detail .wrap_box .box .hint, .top_line.project_detail .wrap_box .box .hint {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 110px;
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 21px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.13);
  border: 1px solid #eaedf1;
  background-color: #fffeeb;
  padding: 10px;
  display: none; }
.metrics.project_detail .wrap_box .box .hint:before, .top_line.project_detail .wrap_box .box .hint:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fffeeb;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0); }
.metrics.project_detail .left, .top_line.project_detail .left {
  padding-right: 22px; }
@media (max-width: 991px) {
  .metrics.project_detail .left, .top_line.project_detail .left {
    padding-right: 0; } }
.metrics.project_detail .left .select_tags tags, .top_line.project_detail .left .select_tags tags {
  border-radius: 4px 4px 0 0; }
.metrics.project_detail .right .wrap_title, .top_line.project_detail .right .wrap_title {
  display: flex;
  align-items: center; }
.metrics.project_detail .right .wrap_title h2, .top_line.project_detail .right .wrap_title h2 {
  margin-bottom: 0; }
.metrics.project_detail .right .wrap_title .notice, .top_line.project_detail .right .wrap_title .notice, .notice {
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 13px;
  letter-spacing: -0.37px;
  padding: 5px 20px 5px 40px;
  border-radius: 4px 0 0 4px;
  background: #fffeeb url("../images/notice.svg") no-repeat 15px center;
  margin-left: 20px;
  margin-right: -20px;
  display: flex;
  align-items: center;
}
.metrics.project_detail .right .line_wrap, .top_line.project_detail .right .line_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px; }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap, .top_line.project_detail .right .line_wrap {
    flex-direction: column; } }
.metrics.project_detail .right .line_wrap .line, .top_line.project_detail .right .line_wrap .line {
  display: flex;
  height: 73px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff; }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap .line, .top_line.project_detail .right .line_wrap .line {
    width: 100%; } }
.metrics.project_detail .right .line_wrap .line:nth-child(-n+2), .top_line.project_detail .right .line_wrap .line:nth-child(-n+2) {
  width: calc(50% - 10px); }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap .line:nth-child(-n+2), .top_line.project_detail .right .line_wrap .line:nth-child(-n+2) {
    width: 100%;
    margin-top: 20px; } }
.metrics.project_detail .right .line_wrap .line:nth-child(1), .top_line.project_detail .right .line_wrap .line:nth-child(1) {
  margin-right: 20px; }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap .line:nth-child(1), .top_line.project_detail .right .line_wrap .line:nth-child(1) {
    margin-right: 0; } }
.metrics.project_detail .right .line_wrap .line:nth-child(3), .top_line.project_detail .right .line_wrap .line:nth-child(3) {
  width: 100%;
  margin-top: 20px; }
.metrics.project_detail .right .line_wrap .line .icon, .top_line.project_detail .right .line_wrap .line .icon {
  width: 57px;
  height: 73px;
  border-radius: 3px 0 0 3px;
  background-color: rgba(226, 233, 239, 0.5); }
.metrics.project_detail .right .line_wrap .line .icon:nth-child(1), .top_line.project_detail .right .line_wrap .line .icon:nth-child(1) {
  background: rgba(226, 233, 239, 0.5) url("../images/participants.svg") no-repeat center center; }
.metrics.project_detail .right .line_wrap .line .wrap_text, .top_line.project_detail .right .line_wrap .line .wrap_text {
  padding: 10px 15px; }
.metrics.project_detail .right .line_wrap .line .wrap_text .title, .top_line.project_detail .right .line_wrap .line .wrap_text .title {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px; }
@media (max-width: 1199px) {
  .metrics.project_detail .right .line_wrap .line .wrap_text .title, .top_line.project_detail .right .line_wrap .line .wrap_text .title {
    font-size: 15px; } }
.metrics.project_detail .right .line_wrap .line .wrap_text .title.color, .top_line.project_detail .right .line_wrap .line .wrap_text .title.color {
  color: #5dba05; }
.metrics.project_detail .right .line_wrap .line .wrap_text .desc, .top_line.project_detail .right .line_wrap .line .wrap_text .desc {
  color: #757c84;
  font-family: Muli-SemiBold;
  font-size: 14px; }
@media (max-width: 1199px) {
  .metrics.project_detail .right .line_wrap .line .wrap_text .desc, .top_line.project_detail .right .line_wrap .line .wrap_text .desc {
    font-size: 12px;
    white-space: nowrap; } }
.metrics.project_detail .right .line_wrap .line .wrap_right, .top_line.project_detail .right .line_wrap .line .wrap_right {
  padding-left: 183px;
  padding-top: 17px; }
@media (max-width: 1199px) {
  .metrics.project_detail .right .line_wrap .line .wrap_right, .top_line.project_detail .right .line_wrap .line .wrap_right {
    padding-left: 80px; } }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap .line .wrap_right, .top_line.project_detail .right .line_wrap .line .wrap_right {
    padding-left: 0; } }
.metrics.project_detail .right .line_wrap .line .wrap_right .text, .top_line.project_detail .right .line_wrap .line .wrap_right .text {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  max-width: 150px; }
@media (max-width: 767px) {
  .metrics.project_detail .right .line_wrap .line .wrap_right .text, .top_line.project_detail .right .line_wrap .line .wrap_right .text {
    font-size: 12px; } }
.metrics.project_detail .right .line_wrap .line:nth-child(2) .icon, .top_line.project_detail .right .line_wrap .line:nth-child(2) .icon {
  background: rgba(226, 233, 239, 0.5) url("../images/high.svg") no-repeat center center; }
.metrics.project_detail .right .line_wrap .line:nth-child(3) .icon, .top_line.project_detail .right .line_wrap .line:nth-child(3) .icon {
  background: rgba(226, 233, 239, 0.5) url("../images/total.svg") no-repeat center center; }
.metrics.project_detail .right .wrap_select, .top_line.project_detail .right .wrap_select {
  display: flex; }
.metrics.project_detail .right .wrap_select input, .top_line.project_detail .right .wrap_select input {
  border-radius: 4px 0 0 4px;
  border-right: none; }
.metrics.project_detail .right .wrap_select select, .top_line.project_detail .right .wrap_select select {
  max-width: 110px;
  border-radius: 0 4px 4px 0; }
.metrics.project_detail .right .wrap_right.no_active h2, .metrics.project_detail .right .wrap_right.no_active .currency, .metrics.project_detail .right .wrap_right.no_active input, .top_line.project_detail .right .wrap_right.no_active h2, .top_line.project_detail .right .wrap_right.no_active .currency, .top_line.project_detail .right .wrap_right.no_active input {
  color: #989da3; }
.metrics.project_detail .right .wrap_right .result, .top_line.project_detail .right .wrap_right .result {
  margin-top: 8px;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px; }
@media (max-width: 1199px) {
  .metrics.project_detail .right .wrap_right .result, .top_line.project_detail .right .wrap_right .result {
    font-size: 11px; } }
.metrics.project_detail .right .run-detail, .top_line.project_detail .right .run-detail {
  position: relative; }
.metrics.project_detail .right .run-detail input, .top_line.project_detail .right .run-detail input {
  padding-left: 50px; }
.metrics.project_detail .right .run-detail input.currency_input, .top_line.project_detail .right .run-detail input.currency_input {
  padding-left: 69px; }
.metrics.project_detail .right .run-detail .icon, .top_line.project_detail .right .run-detail .icon {
  position: absolute;
  width: 40px;
  height: 100%;
  border-radius: 3px 0 0 3px;
  background: rgba(226, 233, 239, 0.5) url("../images/activity.svg") no-repeat center center;
  left: 0;
  z-index: 999; }
.metrics.project_detail .right .run-detail .icon.max, .top_line.project_detail .right .run-detail .icon.max {
  background: rgba(226, 233, 239, 0.5) url("../images/max.svg") no-repeat center center; }
.metrics.project_detail .right .run-detail .icon.time_activity, .top_line.project_detail .right .run-detail .icon.time_activity {
  background: rgba(226, 233, 239, 0.5) url("../images/time_activity.svg") no-repeat center center; }
.metrics.project_detail .right .run-detail .currency, .top_line.project_detail .right .run-detail .currency {
  position: absolute;
  width: 56px;
  height: 100%;
  border-radius: 3px 0 0 3px;
  background: rgba(226, 233, 239, 0.5);
  left: 0;
  z-index: 999;
  color: #808fa3;
  font-family: Muli-Bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  justify-content: center; }
.metrics.project_detail .wrap_info, .top_line.project_detail .wrap_info {
  padding: 0; }
.metrics.project_detail .wrap_info .wrap_radio p, .top_line.project_detail .wrap_info .wrap_radio p {
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  padding-left: 25px; }
.metrics h1, .top_line h1 {
  color: #233539;
  font-family: Muli;
  font-size: 30px;
  letter-spacing: -0.86px; }
.metrics .wrap_box, .top_line .wrap_box {
  display: flex; }
@media (max-width: 1199px) {
  .metrics .wrap_box, .top_line .wrap_box {
    flex-wrap: wrap; } }
.metrics .wrap_box .box, .top_line .wrap_box .box {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 15px 5px 15px;
  margin-right: 14px; }
.metrics .wrap_box .box:last-child, .top_line .wrap_box .box:last-child {
  margin-right: 0; }
@media (max-width: 991px) {
  .metrics .wrap_box .box:nth-child(3), .top_line .wrap_box .box:nth-child(3) {
    margin-right: 0; }
  .metrics .wrap_box .box:nth-child(-n+3), .top_line .wrap_box .box:nth-child(-n+3) {
    margin-bottom: 15px; } }
@media (max-width: 767px) {
  .metrics .wrap_box .box, .top_line .wrap_box .box {
    width: calc(50% - 7.5px); }
  .metrics .wrap_box .box:nth-child(3), .top_line .wrap_box .box:nth-child(3) {
    margin-right: 15px; }
  .metrics .wrap_box .box:nth-child(even), .top_line .wrap_box .box:nth-child(even) {
    margin-right: 0; } }
@media (max-width: 575px) {
  .metrics .wrap_box .box, .top_line .wrap_box .box {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0; }
  .metrics .wrap_box .box:nth-child(even), .top_line .wrap_box .box:nth-child(even) {
    margin-right: 0; }
  .metrics .wrap_box .box:nth-child(3), .top_line .wrap_box .box:nth-child(3) {
    margin-right: 0; }
  .metrics .wrap_box .box:last-child, .top_line .wrap_box .box:last-child {
    margin-bottom: 0; } }
.metrics .wrap_box .box img, .top_line .wrap_box .box img {
  margin-right: 10px; }
.metrics .wrap_box .box .number, .top_line .wrap_box .box .number {
  color: #233539;
  font-size: 30px;
  font-family: Muli-SemiBold; }
.metrics .wrap_box .box .number.orange, .top_line .wrap_box .box .number.orange {
  color: #ffb700; }
.metrics .wrap_box .box .number.soft_red, .top_line .wrap_box .box .number.soft_red {
  color: #fc5a5a; }
.metrics .wrap_box .box .title, .top_line .wrap_box .box .title {
  color: #757c84;
  font-size: 14px;
  font-family: Muli-Bold; }
@media (max-width: 1199px) {
  .metrics .wrap_box .box .title, .top_line .wrap_box .box .title {
    font-size: 15px; } }
@media (max-width: 991px) {
  .metrics .wrap_checkbox, .top_line .wrap_checkbox {
    margin-top: 30px;
    flex-direction: column; }
  .metrics .wrap_checkbox .wrap_filter, .top_line .wrap_checkbox .wrap_filter {
    margin-right: 0;
    margin-bottom: 15px; } }
.metrics .wrap_checkbox .wrap_filter, .top_line .wrap_checkbox .wrap_filter {
  margin-right: 30px; }
.metrics .wrap_checkbox .checkbox, .top_line .wrap_checkbox .checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px; }
.metrics .wrap_checkbox .checkbox + label, .top_line .wrap_checkbox .checkbox + label {
  position: relative;
  padding: 0 0 0 60px;
  opacity: 0.7;
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 12px; }
.metrics .wrap_checkbox .checkbox + label:before, .top_line .wrap_checkbox .checkbox + label:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 2px;
  width: 42px;
  height: 10px;
  border-radius: 13px;
  background-color: rgba(127, 142, 163, 0.25);
  transition: .2s; }
.metrics .wrap_checkbox .checkbox + label:after, .top_line .wrap_checkbox .checkbox + label:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 22px;
  background-color: #acb1b8;
  transition: .2s; }
.metrics .wrap_checkbox .checkbox:checked + label:before, .top_line .wrap_checkbox .checkbox:checked + label:before {
  background: #9FD468; }
.metrics .wrap_checkbox .checkbox:checked + label:after, .top_line .wrap_checkbox .checkbox:checked + label:after {
  left: 26px; }

.metrics .right .box h2.big, .top_line .right .box h2.big {
  color: rgba(35, 53, 57, 0.8) !important;
  font-family: Muli !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: -0.46px !important;
  display: inline-block !important; }
.metrics .right .box .link_icon, .top_line .right .box .link_icon {
  background: url(../images/configure.svg) no-repeat center right !important;
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 10px; }
.metrics .right .box .wrap_icon, .top_line .right .box .wrap_icon {
  padding-left: 50px;
  position: relative; }
.metrics .right .box .wrap_icon.box_1 .icon, .top_line .right .box .wrap_icon.box_1 .icon {
  background: rgba(226, 233, 239, 0.5) url(../images/activity.svg) no-repeat center center; }
.metrics .right .box .wrap_icon.box_2 .icon, .top_line .right .box .wrap_icon.box_2 .icon {
  background: rgba(226, 233, 239, 0.5) url(../images/bax.svg) no-repeat center center; }
.metrics .right .box .wrap_icon.box_3 .icon, .top_line .right .box .wrap_icon.box_3 .icon {
  background: rgba(226, 233, 239, 0.5) url(../images/clock-circle.svg) no-repeat center center; }
.metrics .right .box .wrap_icon.box_4 .icon, .top_line .right .box .wrap_icon.box_4 .icon {
  background: rgba(226, 233, 239, 0.5) url(../images/money.svg) no-repeat center center; }
.metrics .right .box .wrap_icon p, .top_line .right .box .wrap_icon p {
  color: rgba(35, 53, 57, 0.6);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px; }
.metrics .right .box .wrap_icon span, .top_line .right .box .wrap_icon span {
  color: rgba(35, 53, 57, 0.8);
  font-family: "Muli-SemiBold";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.46px; }
.metrics .right .box h2.big.next, .top_line .right .box h2.big.next {
  margin-top: 28px; }
.metrics .right .box h2.big.next.mb, .top_line .right .box h2.big.next.mb {
  margin-top: 15px; }
@media (max-width: 1199px) {
  .metrics .right .box h2.big.next.mb, .top_line .right .box h2.big.next.mb {
    margin-top: 0; } }
.metrics .right .box .wrap_tables .table_wrap span:nth-child(1):not(.heading),
.top_line .right .box .wrap_tables .table_wrap span:nth-child(1):not(.heading) {
  max-width: 75px;
}
.metrics .right .box .table_wrap, .top_line .right .box .table_wrap {
  display: flex;
  margin-bottom: 10px; }
@media (max-width: 1199px) {
  .metrics .right .box .table_wrap, .top_line .right .box .table_wrap {
    flex-direction: column; } }
.metrics .right .box .table_wrap.no_mr, .top_line .right .box .table_wrap.no_mr {
  margin-bottom: 0; }
.metrics .right .box .table_wrap span:nth-child(1), .top_line .right .box .table_wrap span:nth-child(1) {
  color: rgba(35, 53, 57, 0.6);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.13px;
  width: 100%;
  display: block; }
@media (max-width: 1199px) {
  .metrics .right .box .table_wrap span:nth-child(1), .top_line .right .box .table_wrap span:nth-child(1) {
    max-width: none; } }
.metrics .right .box .table_wrap span:nth-child(2), .top_line .right .box .table_wrap span:nth-child(2) {
  color: var(--input-text-color);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  font-weight: 400;
  display: block; }

.cost_calculator {
  background: url("../images/cost_calculator_bg.jpg") no-repeat center center;
  height: 100vh;
  background-size: cover; }
.cost_calculator .btn_open_calc {
  position: fixed;
  width: 40px;
  height: 175px;
  border-radius: 4px 0 0 4px;
  background: #5bc74d url("../images/calc_btn.svg") no-repeat center 90%;
  right: 0;
  top: 50%;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  padding-top: 12px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.46px;
  font-family: opensansbold;
  transition: .5s; }
.cost_calculator .btn_open_calc:hover {
  opacity: .7;
  transition: .5s; }
.cost_calculator .calculator {
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.19);
  border-radius: 4px 0 0 4px;
  border: 1px solid #e2e9ef;
  background-color: #fff;
  right: 0;
  bottom: 160px;
  position: absolute;
  font-family: opensans;
  display: none; }
@media (max-width: 991px) {
  .cost_calculator .calculator {
    right: auto;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    position: fixed; } }
.cost_calculator .calculator .title {
  background-color: rgba(46, 119, 210, 0.1);
  height: 57px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; }
.cost_calculator .calculator .title h1 {
  color: #307fe2;
  font-size: 16px;
  letter-spacing: -0.46px;
  font-family: opensansbold;
  text-transform: uppercase; }
.cost_calculator .calculator .title .close {
  color: rgba(35, 53, 57, 0.7);
  font-size: 16px;
  letter-spacing: -0.46px;
  transition: .5s; }
.cost_calculator .calculator .title .close:hover {
  cursor: pointer;
  opacity: .7;
  transition: .5s; }
.cost_calculator .calculator {
  padding: 20px; }
.cost_calculator .calculator h2 {
  color: #233539;
  font-size: 16px;
  letter-spacing: -0.46px;
  margin-bottom: 10px; }
.cost_calculator .calculator input {
  width: 51px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(127, 142, 163, 0.5);
  background-color: #fff;
  text-align: center;
  color: #233539;
  font-size: 16px;
  letter-spacing: -0.46px; }
.cost_calculator .calculator .mumber_minutes .wrap_input {
  align-items: center;
  justify-content: space-between; }
.cost_calculator .calculator .mumber_minutes .slider {
  margin: 15px 0 40px 0; }
.cost_calculator .calculator .mumber_minutes .slider .line {
  height: 4px;
  border-radius: 4px;
  background-color: rgba(234, 236, 240, 0.5);
  position: relative; }
.cost_calculator .calculator .mumber_minutes .slider .line .process {
  width: 20%;
  height: 100%;
  border-radius: 4px;
  background-color: rgba(91, 199, 77, 0.5); }
.cost_calculator .calculator .mumber_minutes .slider .line .circle {
  width: 26px;
  height: 26px;
  border: 4px solid #5bc74d;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 18%;
  top: 50%;
  transform: translate(0, -50%); }
.cost_calculator .calculator .number_participants {
  margin-bottom: 20px; }
.cost_calculator .calculator .based_off {
  margin-bottom: 20px; }
.cost_calculator .calculator .based_off input {
  width: 60px;
  margin-right: 15px;
  background: rgba(127, 142, 163, 0.2);
  border: none;
  font-family: opensanssemibold; }
.cost_calculator .calculator .total_cost h2 {
  color: #323c47;
  font-size: 16px;
  letter-spacing: -0.46px;
  font-family: opensanssemibold;
  margin-bottom: 0; }
.cost_calculator .calculator .total_cost p {
  color: #323c47;
  font-size: 24px;
  letter-spacing: -0.69px;
  font-family: opensansbold; }

.run_detail_tabs .details {
  display: flex; }
@media (max-width: 1199px) {
  .run_detail_tabs .details {
    flex-wrap: wrap; } }
.run_detail_tabs .details .wrap_box {
  width: 273px;
  min-height: 240px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 15px; }
@media (max-width: 991px) {
  .run_detail_tabs .details .wrap_box {
    width: 337px; } }
@media (max-width: 991px) {
  .run_detail_tabs .details .wrap_box:nth-child(3) {
    margin-top: 15px; } }
@media (max-width: 767px) {
  .run_detail_tabs .details .wrap_box:nth-child(3) {
    margin-top: 0; } }
@media (max-width: 991px) {
  .run_detail_tabs .details .wrap_box:nth-child(even) {
    margin-right: 0; } }
@media (max-width: 767px) {
  .run_detail_tabs .details .wrap_box {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0 !important; } }
.run_detail_tabs .details .wrap_box:last-child {
  margin-right: 0; }
@media (max-width: 1199px) {
  .run_detail_tabs .details .wrap_box {
    margin-top: 15px; } }
@media (max-width: 767px) {
  .run_detail_tabs .details .wrap_box:last-child {
    margin-top: 0;
    margin-bottom: 0; } }
.run_detail_tabs .details .wrap_box.circle {
  padding: 20px; }
.run_detail_tabs .details .wrap_box.circle .wrap_item {
  padding: 0;
  margin-top: 30px; }
.run_detail_tabs .details .wrap_box.circle .wrap_item .item:first-child {
  position: relative;}
.run_detail_tabs .details .wrap_box .wrap_circle {
  display: flex; }
.run_detail_tabs .details .wrap_box .wrap_circle .info {
  margin-left: 25px; }
.run_detail_tabs .details .wrap_box .wrap_circle .info .ratio {
  color: #000000;
  font-family: Muli-SemiBold;
  font-size: 24px; }
.run_detail_tabs .details .wrap_box .wrap_circle .info .status {
  color: #757c84;
  font-family: Muli-Bold;
  font-size: 14px;
  margin-bottom: 5px; }
.run_detail_tabs .details .wrap_box .wrap_circle .info .message {
  color: #e74f4f;
  font-family: Muli-Bold;
  font-size: 16px;
  letter-spacing: -0.46px; }
.run_detail_tabs .details .wrap_box .title {
  height: 55px;
  padding-left: 60px;
  display: flex;
  align-items: center;
  background: rgba(226, 233, 239, 0.5) url("../images/time.svg") no-repeat 20px center;
  color: #808fa3;
  font-family: Muli-SemiBold;
  font-size: 20px; }
.run_detail_tabs .details .wrap_box .title.pay {
  background: rgba(226, 233, 239, 0.5) url("../images/pay.svg") no-repeat 20px center; }
.run_detail_tabs .details .wrap_box .title.quality {
  background: rgba(226, 233, 239, 0.5) url("../images/quality.svg") no-repeat 20px center; }
.run_detail_tabs .details .wrap_box .item:nth-child(-n+2) {
  margin-bottom: 20px; }
@media (max-width: 767px) {
  .run_detail_tabs .details .wrap_box .item {
    margin-bottom: 20px !important; }
  .run_detail_tabs .details .wrap_box .item:last-child {
    margin-bottom: 0 !important; } }
.run_detail_tabs .details .wrap_box .item .number {
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 24px; }
.run_detail_tabs .details .wrap_box .item .number.yellow {
  color: #ffb700; }
.run_detail_tabs .details .wrap_box .item .number.red {
  color: #e74f4f; }
.run_detail_tabs .details .wrap_box .item .text {
  color: #757c84;
  font-family: Muli-Bold;
  font-size: 14px; }
.run_detail_tabs .details .wrap_box .wrap_item {
  padding: 20px;
  display: flex;
  flex-wrap: wrap; }

.run_detail_tabs .panel_info {
  margin-top: 15px;
  width: 100%; }
.run_detail_tabs .panel_info .panel_left {
  max-width: 570px;
  margin-right: 15px;
  padding: 20px; }
@media (max-width: 991px) {
  .run_detail_tabs .panel_info .panel_left {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px; } }
.run_detail_tabs .panel_info .panel_left .wrap_title {
  min-height: 50px;
  box-shadow: 0 1px 0 #e9eff4;
  background-color: #f5f5f6;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 15px;
  padding: 20px; }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_left .wrap_title {
    flex-direction: row; } }
.run_detail_tabs .panel_info .panel_left .wrap_title #dropdownMenu1 {
  float: right;
  margin-left: auto; }
.run_detail_tabs .panel_info .panel_left .wrap_title h2 {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px; }
.run_detail_tabs .panel_info .panel_left .wrap_title .code {
  margin: 0 50px;
  white-space: pre; }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_left .wrap_title .code {
    margin: 0 20px; } }
.run_detail_tabs .panel_info .panel_right {
  padding: 20px;
  height: 100%; }
.run_detail_tabs .panel_info .panel_right.active .btn_save_later {
  background-color: #307fe2;
  color: #fff; }
.run_detail_tabs .panel_info .panel_right.active .right_form span {
  color: #323c47; }
.run_detail_tabs .panel_info .panel_right.active .right_form span:before {
  border: 1px solid rgba(128, 143, 163, 0.7); }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_right .wrap_link {
    flex-direction: row; }
  .run_detail_tabs .panel_info .panel_right .wrap_link .btn_save_later {
    margin-right: 15px; } }
.run_detail_tabs .panel_info .panel_right .wrap_form {
  display: flex;
  justify-content: space-between; }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_right .wrap_form {
    flex-direction: column; } }
.run_detail_tabs .panel_info .panel_right label {
  display: block;
  margin-bottom: 0; }
.run_detail_tabs .panel_info .panel_right .wrap_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px; }
.run_detail_tabs .panel_info .panel_right .wrap_title .title_block {
  color: #e74f4f;
  font-family: Muli-Bold;
  font-size: 20px;
  letter-spacing: -0.57px; }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_right .wrap_title .title_block {
    margin-bottom: 20px; } }
.run_detail_tabs .panel_info .panel_right .left_form {
  flex-shrink: 0;
  margin-right: 40px; }
@media (max-width: 767px) {
  .run_detail_tabs .panel_info .panel_right .left_form {
    margin-right: 0;
    margin-bottom: 20px; } }
.run_detail_tabs .panel_info .panel_right .left_form label {
  height: 50px;
  box-shadow: 0 1px 0 #e9eff4;
  background-color: rgba(245, 245, 246, 0.4);
  border-top: 1px solid #e9eff4;
  padding: 0 25px;
  display: flex;
  align-items: center; }
.run_detail_tabs .panel_info .panel_right .left_form label:last-child, .run_detail_tabs .panel_info .panel_right .left_form label:first-child {
  border-top: none; }
.run_detail_tabs .panel_info .panel_right .left_form label:nth-child(n+2) {
  background: url("../images/doubts-button.svg") no-repeat right 15px center; }
.run_detail_tabs .panel_info .panel_right .left_form label.title {
  background-color: #f5f5f6; }
.run_detail_tabs .panel_info .panel_right .right_form .title_form {
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.4px;
  margin-bottom: 25px; }
.run_detail_tabs .panel_info .panel_right .right_form label {
  display: flex;
  margin-bottom: 20px; }
.run_detail_tabs .panel_info .panel_right .right_form label:last-child {
  margin-bottom: 0; }
.run_detail_tabs .panel_info .panel_right .right_form span {
  color: #c9cccf;
  font-family: Muli-Bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.4px; }
.run_detail_tabs .panel_info .panel_right .right_form span:before {
  border: 1px solid #c9cccf; }
.run_detail_tabs .panel_info .panel_right .btn_save_later {
  background-color: #919a9c;
  color: #b5bbbc;
  border: 1px solid transparent; }
.run_detail_tabs .panel_info.panel-success {
  padding-top: 20px; }

.run_detail_tabs .border_top {
  border-top: 1px solid #808fa3; }

@media (max-width: 767px) {
  .run_detail_tabs h1 {
    margin-top: 15px; } }

.run_detail_tabs.progress .indicator {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
  margin: 0; }
@media (max-width: 991px) {
  .run_detail_tabs.progress .indicator {
    height: auto; } }

@media (max-width: 767px) {
  .run_detail_tabs .wrap_title {
    flex-direction: column;
    align-items: flex-start !important; }
  .run_detail_tabs .wrap_title h1 {
    margin-left: 0; } }

.run_detail_tabs h1 {
  color: #323c47;
  font-size: 16px;
  font-family: Muli-SemiBold;
  margin-bottom: 20px; }

.run_detail_tabs h3 {
  color: #233539;
  font-size: 20px;
  letter-spacing: -0.57px;
  font-family: Muli-Bold;
  margin-bottom: 10px; }

.run_detail_tabs .tabs {
  margin-bottom: 40px; }
.run_detail_tabs .tabs a {
  color: rgba(35, 53, 57, 0.5);
  font-size: 30px;
  letter-spacing: -0.86px;
  font-family: Muli;
  margin-right: 40px;
  padding-bottom: 10px;
  transition: .5s; }
@media (max-width: 767px) {
  .run_detail_tabs .tabs a {
    font-size: 22px; } }
@media (max-width: 575px) {
  .run_detail_tabs .tabs a {
    font-size: 14px;
    margin-right: 15px; } }
.run_detail_tabs .tabs a.active {
  border-bottom: 3px solid rgba(48, 127, 226, 0.7);
  color: #233539; }
.run_detail_tabs .tabs a:hover {
  transition: .5s;
  opacity: .7; }
.run_detail_tabs .tabs li:last-child a {
  margin-right: 0; }

.run_detail_tabs .panel-success {
  padding: 0 20px 20px 20px;
  background: #fff; }

.run_detail_tabs .panel-heading {
  height: 70px;
  box-shadow: 0 1px 0 #e9eff4;
  background-color: #f5f5f6;
  align-items: center;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 0 20px; }
.run_detail_tabs .panel-heading h2 {
  color: #233539;
  font-size: 20px;
  letter-spacing: -0.57px;
  font-family: Muli-Bold;
  margin-right: 10px; }
@media (max-width: 991px) {
  .run_detail_tabs .panel-heading h2 {
    font-size: 15px; } }
@media (max-width: 767px) {
  .run_detail_tabs .panel-heading h2 {
    font-size: 12px; } }
.run_detail_tabs .panel-heading .code {
  color: var(--input-text-color);
  font-size: 20px;
  font-family: Muli-SemiBold; }
@media (max-width: 767px) {
  .run_detail_tabs .panel-heading .code {
    font-size: 12px; } }

.run_detail_tabs .metrics {
  border-bottom: 1px solid #e2e9ef;
  margin: 0 -20px;
  padding: 0 20px; }
.run_detail_tabs .metrics .wrap_box {
  min-height: 112px;
  align-items: center;
  justify-content: space-between; }
@media (max-width: 1199px) {
  .run_detail_tabs .metrics .wrap_box {
    justify-content: flex-start; } }
.run_detail_tabs .metrics .wrap_box .box {
  box-shadow: none;
  margin: 0;
  padding: 0;
  background: none; }
@media (max-width: 1199px) {
  .run_detail_tabs .metrics .wrap_box .box {
    padding: 15px; } }
.run_detail_tabs .metrics .wrap_box .box .number.strong_green {
  color: #5dba05; }

.run_detail_tabs .indicator {
  margin: 30px 0 20px 0; }
.run_detail_tabs .indicator.bg {
  margin-top: 0;
  margin-bottom: 20px;
  height: 109px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center; }
.run_detail_tabs .indicator.bg .row {
  width: 100%; }
.run_detail_tabs .indicator .completed {
  olor: #233539;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-Bold;
  margin-right: 20px; }
.run_detail_tabs .indicator .rejected {
  color: #e74f4f;
  font-size: 14px;
  letter-spacing: -0.4px;
  font-family: Muli-Bold; }
.run_detail_tabs .indicator .line {
  height: 11px;
  border-radius: 5px;
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  margin-top: 10px; }
.run_detail_tabs .indicator .line .line_progress {
  width: 90%;
  height: 100%;
  border-radius: 18px;
  background-color: #5bc74d; }
.run_detail_tabs .indicator .wrap_text {
  margin-top: 30px; }
.run_detail_tabs .indicator .wrap_text p {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 16px;
  letter-spacing: -0.46px;
  margin-bottom: 10px; }
.run_detail_tabs .indicator .wrap_text p:last-child {
  margin-bottom: 0; }
.run_detail_tabs .indicator .wrap_text p span {
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 16px;
  letter-spacing: -0.46px;
  margin-left: 10px; }

.run_detail_tabs .top_box .left_box {
  padding-right: 30px; }

.run_detail_tabs .bottom_box {
  margin: 0 -20px;
  padding: 20px 20px 0 20px;
  border-top: 1px solid #e2e9ef; }

.add_platform .wrap_link {
  margin-top: 40px; }

.add_platform span.live {
  color: #5cc74d; }

.add_platform #aws-key-guide {
  display: none; }

.add_platform .alert-warning {
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fffdea;
  padding: 20px;
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.4px;
  margin-left: 10px; }
@media (max-width: 991px) {
  .add_platform .alert-warning {
    margin-top: 15px;
    margin-left: 0; } }

.add_platform .panel-body {
  background-color: #fff;
  padding: 20px; }
.add_platform .panel-body label {
  display: block;
  margin-bottom: 5px; }
.add_platform .panel-body input, .add_platform .panel-body select {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #fff;
  margin-bottom: 15px; }
.add_platform .panel-body .help-block {
  margin-bottom: 10px !important;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.4px; }
.add_platform .panel-body .text-info {
  margin-bottom: 25px; }
.add_platform .panel-body .text-info a {
  color: #1277fb;
  font-family: Muli-SemiBold;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  letter-spacing: -0.4px; }
.add_platform .panel-body .add-platform {
  height: 40px;
  border-radius: 4px;
  background-color: #307fe2;
  color: #fff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  border: 1px solid transparent;
  outline: none;
  transition: .5s;
  margin-right: 25px; }
@media (max-width: 767px) {
  .add_platform .panel-body .add-platform {
    margin-bottom: 0; } }
.add_platform .panel-body .add-platform:hover {
  transition: .5s;
  background-color: #fff;
  color: #307fe2;
  border: 1px solid #307fe2;
  cursor: pointer; }
.add_platform .panel-body .btn-lg {
  color: #808fa3;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  transition: .5s; }
.add_platform .panel-body .btn-lg:hover {
  cursor: pointer;
  transition: .5s;
  opacity: .7; }

.add_platform .panel.panel-default {
  margin-top: 0; }

.project_block {
  text-align: center; }
.project_block .wrap_top {
  background-color: rgba(48, 127, 226, 0.05);
  padding: 40px 30px 30px 30px; }
.project_block .wrap_top .title {
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 24px;
  margin: 5px 0 20px 0; }
.project_block .wrap_top h3 {
  color: #323c47;
  font-family: "Muli-Bold";
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px; }
.project_block .wrap_top p {
  color: #323c47;
  font-family: Muli;
  font-size: 18px;
  line-height: 28px; }
.project_block .wrap_top .wrap_btn {
  display: flex;
  justify-content: center;
  margin: 30px auto; }
@media (max-width: 767px) {
  .project_block .wrap_top .wrap_btn {
    flex-direction: column;
    align-items: center; } }
.project_block .wrap_top .wrap_btn .demo {
  margin-right: 20px;
  width: 208px;
  height: 55px;
  display: flex;
  justify-content: center; }
@media (max-width: 767px) {
  .project_block .wrap_top .wrap_btn .demo {
    margin-right: 0;
    margin-bottom: 15px; } }
.project_block .wrap_top .wrap_btn .create {
  width: 208px;
  height: 55px; }
.project_block .wrap_top .text {
  color: #233539;
  font-family: Muli;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 30px; }
.project_block .wrap_top .text a {
  color: #307fe2;
  text-decoration: underline;
  transition: .5s; }
.project_block .wrap_top .text a:hover {
  opacity: .7;
  transition: .5s; }
.project_block.no_data img {
  display: inline-block;
  margin-top: 30px; }
.project_block.no_data .btn.btn-primary {
  padding: 0 20px;
  width: auto; }
.project_block.no_data .wrap_top .wrap_btn {
  margin: 30px auto 0 auto; }
.project_block.no_data .wrap_top .title {
  margin: 0 0 10px 0; }
.project_block.no_data .wrap_top p {
  font-size: 20px;
  font-family: Muli-SemiBold; }

.billing_page {
  margin-bottom: 15px; }
@media (max-width: 1199px) {
  .billing_page .btn-primary {
    padding: 0px 10px;
    height: 34px; } }
@media (max-width: 767px) {
  .billing_page .btn-primary {
    margin-bottom: 0; } }
@media (max-width: 991px) {
  .billing_page .row {
    flex-direction: column; } }
.billing_page .item {
  padding: 0 7.5px; }
.billing_page .item:last-child {
  padding-right: 0;
  width: 38%; }
.billing_page .item:nth-child(2) {
  width: 30%; }
.billing_page .item:first-child {
  padding-left: 0;
  width: 32%; }
@media (max-width: 991px) {
  .billing_page .item {
    width: 100% !important;
    margin-bottom: 15px;
    padding: 0 !important; } }
.billing_page button {
  outline: none;
  border: none; }
.billing_page button:hover {
  cursor: pointer; }
.billing_page .panel-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px; }
.billing_page .panel-heading h2 {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px;
  letter-spacing: -0.57px; }
@media (max-width: 1199px) {
  .billing_page .panel-heading h2 {
    font-size: 16px; } }
.billing_page .panel-heading .link {
  color: #307fe2;
  font-family: Muli-SemiBold;
  font-size: 14px;
  text-decoration: underline;
  transition: .5s; }
.billing_page .panel-heading .link:hover {
  opacity: .6;
  transition: .5s; }
.billing_page .panel-body {
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  border: none;
  text-align: center;
  padding: 0 15px; }
.billing_page .panel-body .label {
  color: #233539;
  font-family: Muli-SemiBold;
  font-size: 36px;
  letter-spacing: -1.03px;
  margin-bottom: 10px; }
@media (max-width: 1199px) {
  .billing_page .panel-body .label {
    font-size: 28px; } }
.billing_page .panel-body h2 {
  color: var(--input-text-color);
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px; }
@media (max-width: 1199px) {
  .billing_page .panel-body h2 {
    font-size: 13px; } }
.billing_page .panel-body .wrap_left {
  padding-right: 30px;
  position: relative; }
.billing_page .panel-body .wrap_left:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 59px;
  background: rgba(35, 53, 57, 0.3);
  top: 13px;
  right: 0; }
@media (max-width: 1199px) {
  .billing_page .panel-body .wrap_left:before {
    top: 5px;
    right: 5px; } }
@media (max-width: 991px) {
  .billing_page .panel-body .wrap_left:before {
    content: none; } }
.billing_page .credits {
  height: 208px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px; }
@media (max-width: 767px) {
  .billing_page .credits {
    height: auto; } }
.billing_page .credits.spending .panel-body {
  justify-content: center; }
.billing_page .cart_block .panel-heading {
  margin-bottom: 15px; }
.billing_page .cart_block .panel-body {
  display: flex;
  justify-content: space-between;
  padding: 0; }
@media (max-width: 767px) {
  .billing_page .cart_block .panel-body {
    flex-direction: column; } }
.billing_page .cart_block .panel-body .cart {
  width: 195px;
  height: 122px;
  text-align: left;
  padding: 0 20px; }
.billing_page .cart_block .panel-body .cart.cart_front {
  background: url("../images/cart_img_top.svg") no-repeat 8px 35px, url("../images/cart_img_bottom.svg") no-repeat 149px 93px, linear-gradient(77deg, whitesmoke 0%, whitesmoke 100%);
  margin-right: 15px; }
@media (max-width: 1199px) {
  .billing_page .cart_block .panel-body .cart.cart_front {
    background: url("../images/cart_img_top.svg") no-repeat 8px 35px, url("../images/cart_img_bottom.svg") no-repeat 109px 93px, linear-gradient(77deg, whitesmoke 0%, whitesmoke 100%);
    margin-right: 15px; } }
.billing_page .cart_block .panel-body .cart.cart_front .number, .billing_page .cart_block .panel-body .cart.cart_front .date {
  color: #536e86;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.36px;
  font-family: Muli-SemiBold; }
@media (max-width: 1199px) {
  .billing_page .cart_block .panel-body .cart.cart_front .number, .billing_page .cart_block .panel-body .cart.cart_front .date {
    font-size: 10px; } }
.billing_page .cart_block .panel-body .cart.cart_front .number {
  padding-top: 70px;
  margin-bottom: 9px; }
@media (max-width: 767px) {
  .billing_page .cart_block .panel-body .cart.cart_front {
    margin-bottom: 15px; } }
.billing_page .cart_block .panel-body .cart.cart_back {
  background: #f5f5f5 url("../images/cart_back.svg") no-repeat center 20px; }
.billing_page .cart_block .panel-body .cart.cart_back .code {
  color: #536e86;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.36px;
  font-family: Muli-SemiBold;
  text-align: right;
  padding-top: 57px; }
@media (max-width: 1199px) {
  .billing_page .cart_block .panel-body .cart.cart_back .code {
    font-size: 10px; } }

.billing_table .wrapper_link a.clear {
  margin-right: 15px; }

.billing_table .list-item {
  min-height: 72px; }
.billing_table .list-item span {
  color: rgba(50, 60, 71, 0.5); }

@media (min-width: 991px) {
  .billing_table .list-item span {
    color: red;
    font-family: Muli-Bold;
  }
}

.billing_table .wrap_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 75px; }
@media (max-width: 991px) {
  .billing_table .wrap_title {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
  } }

.billing_table .wrap_title h2 {
  color: #233539;
  font-family: Muli-Bold;
  font-size: 20px;
  letter-spacing: -0.57px; }
@media (max-width: 991px) {
  .billing_table .wrap_title h2 {
    margin-bottom: 20px; } }

.wrapper_link.right {
  text-align: right; }

@media (max-width: 991px) {
  .wrapper_link {
    text-align: left;
    display: flex;
    flex-direction: column; } }

.wrapper_link a {
  border-radius: 4px;
  border: 1px solid #307fe2;
  padding: 5px 10px 5px 35px;
  color: #307fe2;
  font-size: 14px;
  font-family: Muli-Bold;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  transition: .5s; }
.wrapper_link a:last-child {
  margin-right: 0; }
.wrapper_link a.mail {
  margin-right: 0; }
.wrapper_link a:hover {
  transition: .5s;
  opacity: .7; }
.wrapper_link a.up {
  background: url("../images/link_1.svg") no-repeat 10px center; }
.wrapper_link a.down {
  background: url("../images/link_2.svg") no-repeat 10px center; }
.wrapper_link a.unreject_active {
  background: url("../images/unreject_active.svg") no-repeat 10px center; }
.wrapper_link a.set_active {
  background: url("../images/set_active.svg") no-repeat 10px center; }
.wrapper_link a.give_active {
  background: url("../images/give_active.svg") no-repeat 10px center; }
.wrapper_link a.send_active {
  background: url("../images/send_active.svg") no-repeat 10px center; }
.wrapper_link a.print_active {
  background: url("../images/print_active.svg") no-repeat 10px center; }
.wrapper_link a.integrations_delete{
  background: url("../images/integrations_delete.svg") no-repeat 10px center;}
.wrapper_link a.line {
  background: url("../images/link_6.svg") no-repeat 10px center;
  position: relative; }
.wrapper_link a.line .number {
  position: absolute;
  width: 17px;
  height: 17px;
  border: 1px solid #f1f1f1;
  background-color: #e74f4f;
  right: calc(-17px / 2);
  top: calc(-17px / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  font-family: "Muli-Bold";
  font-size: 10px;
  letter-spacing: -0.29px;
  z-index: 1; }
.wrapper_link a.clear {
  border-radius: 2px;
  background-color: #e2e9ef;
  color: rgba(35, 53, 57, 0.5);
  font-family: "Muli-SemiBold";
  font-size: 14px;
  letter-spacing: -0.4px;
  padding: 0 15px;
  margin-right: 0;
  border: none; }
@media (max-width: 991px) {
  .wrapper_link a.clear {
    left: 0; } }
.wrapper_link a.clear:hover {
  opacity: 1;
  background: rgba(35, 53, 57, 0.5);
  color: #e2e9ef; }
@media (max-width: 991px) {
  .wrapper_link a {
    margin-right: 0;
    margin-bottom: 15px; } }

.wrapper_link.no-active {
  text-align: right; }
.wrapper_link.no-active a {
  color: #919a9c;
  border: 1px solid #919a9c; }
.wrapper_link.no-active .down {
  background: url("../images/down_no_active.svg") no-repeat 10px center; }
.wrapper_link.no-active .add {
  background: url("../images/add_no_active.svg") no-repeat 10px center; }
.wrapper_link.no-active .box {
  background: url("../images/box_no_active.svg") no-repeat 10px center; }
.wrapper_link.no-active .mail {
  background: url("../images/mail_no_active.svg") no-repeat 10px center; }

.wrapper_link.pop_up {
  margin-top: 20px; }
.wrapper_link.pop_up a {
  padding-bottom: 3px; }
.wrapper_link.pop_up .add {
  background: url("../images/pop_up_add.svg") no-repeat 10px center; }
.wrapper_link.pop_up .box {
  background: url("../images/pop_up_box.svg") no-repeat 10px center; }
.wrapper_link.pop_up .mail {
  background: url("../images/pop_up_mail.svg") no-repeat 10px center; }

.accordion {
  text-align: left;
  padding: 40px 20px;
  background: #fff; }
.accordion .accordion_item {
  position: relative; }
.accordion .title_block {
  color: #323c47;
  font-family: Muli-SemiBold;
  font-size: 16px;
  transition: all 0.2s;
  padding-left: 50px;
  padding-bottom: 20px;
  padding-top: 3px; }
.accordion .title_block:last-child {
  margin-bottom: 0; }
.accordion .title_block:hover {
  opacity: .7;
  transition: .5s;
  cursor: pointer; }
.accordion .title_block:before {
  content: '+';
  width: 33px;
  height: 33px;
  background: #e0ecfb;
  color: #8392a5;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  box-sizing: border-box; }
.accordion .active_block .title_block:before {
  content: '-';
  width: 33px;
  height: 33px;
  background: #e0ecfb;
  color: #8392a5;
  display: block;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  box-sizing: border-box; }
.accordion .active_block .title_block {
  background: #f5f9fe; }
.accordion .info {
  display: none;
  padding: 0 50px;
  overflow: hidden;
  background: #f5f9fe; }
.accordion .info_item {
  margin-bottom: 20px; }
.accordion p {
  color: #5b636c;
  font-size: 14px;
  line-height: 21px;
  font-family: Muli; }
.accordion p a {
  color: #307fe2;
  font-family: Muli-SemiBold;
  text-decoration: underline;
  transition: .5s; }
.accordion p a:hover {
  opacity: .7;
  transition: .5s; }

.account {
  max-width: 563px;
  width: 100%;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px; }
.account .wrap_label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
.account input[type=password] {
  width: 50%;
  border: none; }

.account .wrap_pass {
  display: flex;
  justify-content: space-between;
  align-items: center; }
@media (max-width: 767px) {
  .account .wrap_pass {
    flex-direction: column;
    align-items: flex-start; } }
.account .wrap_pass .pass {
  color: #307fe2;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px; }
@media (max-width: 767px) {
  .account .wrap_pass .pass {
    margin-top: 5px; } }
.account .wrap_title {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
.account .wrap_title .title {
  color: #808fa3;
  font-family: Muli-Bold;
  font-size: 16px;
  letter-spacing: -0.46px; }
.account .wrap_title .line {
  width: 100%;
  height: 1px;
  background: rgba(128, 143, 163, 0.5);
  margin-left: 15px;
  position: relative;
  top: 2px; }
.account .wrap_select {
  display: flex; }
@media (max-width: 767px) {
  .account .wrap_select {
    flex-direction: column; } }
.account .wrap_select input {
  width: 70%;
  border-radius: 4px 0 0 4px;
  border-right: none; }
@media (max-width: 767px) {
  .account .wrap_select input {
    width: 100%;
    border-radius: 4px;
    border-right: none;
    margin-bottom: 15px;
    border: 1px solid #e2e9ef; } }
.account .wrap_select select {
  width: 30%;
  border-radius: 0 4px 4px 0; }
@media (max-width: 767px) {
  .account .wrap_select select {
    width: 100%;
    border-radius: 4px; } }
.account .info, .wrap_info .help-block {
  color: var(--input-text-color);
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 10px; }
.account label {
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 10px; }
.account input, .account select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  letter-spacing: -0.4px;
  padding-left: 20px; }
.account .update {
  width: 89px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(35, 53, 57, 0.5);
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px; }
.account .update:hover {
  transition: .5s;
  opacity: 0.5; }
.account .cvv {
  display: flex;
  justify-content: space-between; }
@media (max-width: 767px) {
  .account .cvv {
    flex-direction: column; } }
.account .cvv .wrap_label {
  width: 100%; }
.account .cvv .wrap_label:first-child {
  margin-right: 15px; }

.project_list.metrics .box {
  width: 216px;
  height: 94px; }
.project_list.metrics .box .number {
  font-size: 35px; }
.project_list.metrics .box .title {
  font-size: 16px; }
@media (max-width: 1199px) {
  .project_list.metrics .box:last-child {
    margin-top: 15px; } }
@media (max-width: 991px) {
  .project_list.metrics .box:last-child {
    margin-top: 0; } }
@media (max-width: 575px) {
  .project_list.metrics .box {
    width: 100%; } }

.integrations .item {
  min-height: 132px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 20px; }
.integrations .item:last-child {
  margin-bottom: 0; }
.integrations .item .wrap_box {
  display: flex; }
@media (max-width: 767px) {
  .integrations .item .wrap_box {
    flex-direction: column;
    align-items: center; } }
.integrations .item .wrap_box .wrap_img {
  width: 90px;
  height: 90px;
  border: 1px solid #eaedf1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0; }
@media (max-width: 767px) {
  .integrations .item .wrap_box .wrap_img {
    margin-right: 0;
    margin-bottom: 20px; } }
.integrations .item .wrap_box h2 {
  color: #233539;
  font-family: "Muli-Bold";
  font-size: 20px;
  margin-bottom: 8px; }
.integrations .item .wrap_box p {
  color: #233539;
  font-family: "Muli-SemiBold";
  font-size: 14px; }
.integrations .item .btn-primary {
  padding: 0 15px;
  height: 40px;
  float: right;
  padding-left: 40px;
  background: #307fe2 url("../images/connect.svg") no-repeat 14px center; }
@media (max-width: 767px) {
  .integrations .item .btn-primary {
    float: none;
    margin-top: 20px;
    display: inline-flex; } }
.integrations .item .btn_save_later {
  width: 115px;
  float: right;
  margin-right: 0; }
@media (max-width: 767px) {
  .integrations .item .btn_save_later {
    float: none;
    margin-top: 20px; } }

.integrations .wrap_detail {
  width: 100%;
  margin-top: 20px; }
.integrations .wrap_detail h3 {
  margin: 0 -20px 0 -20px;
  padding: 25px 0 17px 20px;
  color: #233539;
  font-family: Muli-Bold;
  font-size: 16px;
  letter-spacing: -0.46px;
  border-top: 1px solid #e2e9ef; }
.integrations .wrap_detail .wrap_projects {
  box-shadow: none;
  border-radius: 0; }
.integrations .wrap_detail .wrap_projects .list-item {
  box-shadow: none;
  margin-left: -20px;
  margin-right: -20px; }
.integrations .wrap_detail .wrap_projects .list-item .mail {
  color: rgba(35, 53, 57, 0.7);
  font-family: Muli-SemiBold;
  font-size: 14px;
  margin-left: 6px; }
@media (max-width: 991px) {
  .integrations .wrap_detail .wrap_projects .list-item .mail {
    margin-left: 0; } }
.integrations .wrap_detail .wrap_projects .list-item .live {
  color: #5cc74d;
  font-family: Muli-Bold;
  font-size: 14px; }
.integrations .wrap_detail .wrap_projects .wrap_operation {
  width: 100%; }
@media (max-width: 991px) {
  .integrations .wrap_detail .wrap_projects .wrap_operation {
    justify-content: flex-start; } }
.integrations .wrap_detail .wrap_projects .wrap_operation a {
  transition: .5s; }
.integrations .wrap_detail .wrap_projects .wrap_operation a:hover {
  opacity: .7;
  transition: .5s; }
.integrations .wrap_detail .wrap_projects .wrap_operation .edit {
  background: url("../images/integrations_edit.svg") no-repeat;
  width: 18px;
  height: 18px; }
.integrations .wrap_detail .wrap_projects .wrap_operation .delete {
  background: url("../images/integrations_delete.svg") no-repeat;
  width: 16px;
  height: 18px; }

.custom_attributes .wrapper_link {
  margin: 0 0 30px auto; }

.custom_attributes .dropup {
  text-align: right; }
.custom_attributes .dropup #dropdownMenu1 {
  margin-left: auto;
  background: url("../images/options_attr.svg") no-repeat; }

.participants .panel.panel-default, .run_detail_tabs .panel.panel-default {
  margin-top: 30px; }

.happiness_succesfully {
  position: absolute;
  left: 0;
  top: 69px;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(92, 199, 77, 0.7);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center; }
.happiness_succesfully p {
  color: #ffffff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  background: url("../images/check.svg") no-repeat left center;
  padding-left: 25px; }

.bootstrap-datetimepicker-widget {
  width: 408.8px !important;
  box-shadow: 0 2px 23px #ededed;
  border-radius: 6px 6px 0 0;
  background-color: #ffffff;
  position: absolute;
  left: 0 !important;
  top: 50px !important;
  z-index: 9999 !important;
  padding: 43px 30px 30px 30px !important; }
.bootstrap-datetimepicker-widget a[data-action="close"] {
  width: 95px;
  height: 40px;
  border-radius: 4px;
  background-color: #307fe2;
  padding-top: -4px;
  right: 45px;
  top: 455px;
  position: absolute;
  z-index: 9999; }
.bootstrap-datetimepicker-widget a[data-action="close"] span {
  display: none; }
.bootstrap-datetimepicker-widget a[data-action="close"]:before {
  content: 'Apply';
  color: #ffffff;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: 0.13px;
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center; }
.bootstrap-datetimepicker-widget a[data-action="close"]:hover {
  background-color: #307fe2;
  opacity: .7;
  transition: .5s;
  cursor: pointer; }
.bootstrap-datetimepicker-widget .prev {
  position: relative;
  background: url("../images/calendar_date_l.svg") no-repeat;
  right: -288px;
  top: -9px;
  transition: .5s; }
.bootstrap-datetimepicker-widget .prev:hover {
  background: url("../images/calendar_date_l.svg") no-repeat !important;
  opacity: .7;
  transition: .5s; }
.bootstrap-datetimepicker-widget .next {
  position: absolute;
  background: url("../images/calendar_date_r.svg") no-repeat;
  right: -170px;
  top: -8px;
  transition: .5s; }
.bootstrap-datetimepicker-widget .next:hover {
  background: url("../images/calendar_date_r.svg") no-repeat !important;
  opacity: .7;
  transition: .5s; }
.bootstrap-datetimepicker-widget .picker-switch {
  color: #808fa3;
  font-family: Muli-SemiBold;
  font-size: 15px;
  letter-spacing: 0.59px;
  position: absolute;
  left: 15px;
  top: -13px;
  width: 100% !important;
  text-align: left;
  transition: .5s; }
.bootstrap-datetimepicker-widget .picker-switch:hover {
  background: none !important;
  opacity: .7;
  transition: .5s; }
.bootstrap-datetimepicker-widget .dow {
  color: rgba(35, 53, 57, 0.7);
  font-family: Muli-Bold;
  font-size: 16px;
  line-height: 19.01px;
  text-transform: uppercase; }
.bootstrap-datetimepicker-widget .row {
  flex-direction: column; }
.bootstrap-datetimepicker-widget .accordion-toggle a[data-action="today"], .bootstrap-datetimepicker-widget .accordion-toggle a[data-action="clear"] {
  display: none; }
.bootstrap-datetimepicker-widget .datepicker .datepicker-months td, .bootstrap-datetimepicker-widget .datepicker .datepicker-decades td, .bootstrap-datetimepicker-widget .datepicker .datepicker-years td {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  line-height: normal;
  width: 100%;
  width: 350.4px; }
.bootstrap-datetimepicker-widget .datepicker .datepicker-months td span, .bootstrap-datetimepicker-widget .datepicker .datepicker-decades td span, .bootstrap-datetimepicker-widget .datepicker .datepicker-years td span {
  width: 25%; }
.bootstrap-datetimepicker-widget .datepicker table {
  width: auto; }
.bootstrap-datetimepicker-widget .datepicker td {
  color: #5c6069;
  font-family: Muli-Bold;
  font-size: 14px;
  padding: 15px;
  border-radius: 50%; }
.bootstrap-datetimepicker-widget .datepicker td.active, .bootstrap-datetimepicker-widget .datepicker td.today {
  width: 38px;
  height: 38px;
  background-color: #5cc74d;
  color: #fff;
  border-radius: 50%; }
.bootstrap-datetimepicker-widget .datepicker td.active:before, .bootstrap-datetimepicker-widget .datepicker td.today:before {
  content: none; }
.bootstrap-datetimepicker-widget .datepicker td.old {
  color: #e2e9ef; }
.bootstrap-datetimepicker-widget .datepicker td.old:hover {
  color: #5c6069; }
.bootstrap-datetimepicker-widget .timepicker {
  border-top: 1px solid #eaedf1;
  max-width: 100%;
  padding: 25px 15px 0 40px;
  position: relative;
  margin-top: 20px; }
.bootstrap-datetimepicker-widget .timepicker:before {
  content: 'Time';
  color: #323c47;
  font-family: Muli-Bold;
  font-size: 14px;
  letter-spacing: -0.4px;
  position: absolute;
  left: 0;
  top: 40px; }
.bootstrap-datetimepicker-widget .timepicker .table-condensed td {
  font-family: Muli-SemiBold;
  text-align: center; }
.bootstrap-datetimepicker-widget .timepicker td {
  height: 2px;
  width: auto;
  line-height: normal;
  text-align: left;
  border-radius: 0;
  vertical-align: middle;
  position: relative; }
.bootstrap-datetimepicker-widget .timepicker td a[data-action="decrementHours"] span {
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0); }
.bootstrap-datetimepicker-widget .timepicker td a[data-action="incrementHours"] span {
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0); }
.bootstrap-datetimepicker-widget .timepicker td a[data-action="decrementMinutes"] span {
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0); }
.bootstrap-datetimepicker-widget .timepicker td a[data-action="incrementMinutes"] span {
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0); }
.bootstrap-datetimepicker-widget .timepicker td span {
  display: inline;
  width: auto;
  height: auto;
  cursor: pointer;
  border-radius: 0;
  line-height: normal;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  margin: 0; }
.bootstrap-datetimepicker-widget .timepicker td span.glyphicon-chevron-up {
  height: 10px;
  width: 10px;
  background: url("../images/calendar_time_t.svg") no-repeat center center;
  position: absolute;
  top: 11px;
  width: 65px; }
.bootstrap-datetimepicker-widget .timepicker td span.glyphicon-chevron-down {
  height: 10px;
  width: 10px;
  background: url("../images/calendar_time_b.svg") no-repeat center center;
  position: absolute;
  bottom: 11px;
  width: 65px; }
.bootstrap-datetimepicker-widget .timepicker td span.timepicker-hour, .bootstrap-datetimepicker-widget .timepicker td span.timepicker-minute {
  border: none;
  background: none; }
.bootstrap-datetimepicker-widget .timepicker .btn-primary {
  width: 75px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e2e9ef;
  background-color: #ffffff;
  color: var(--input-text-color);
  font-family: Muli-SemiBold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.13px;
  cursor: pointer;
}

.pull-left {
  float: left;
}

.list-item-label {
  display: none;
  color: rgba(50, 60, 71, 0.5);
}

@media(max-width: 991px) {
  .list-item-label {
    display: inline-block;
    color: #233539 !important;
  }

  .status_style {
    position: static;
    justify-content: normal;
  }

  .status_style .action {
    position: absolute;
    right: 15px;
    top: 22px;
  }
}
