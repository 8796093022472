.btn-back {
  border: 1px solid #548dcd;
  color: #548dcd;
  padding: 0 15px;
  font-size: 14px;
  letter-spacing: 0.13px;
  font-family: Muli-Bold;
  border-radius: 4px;
  background: none;
  transition: .5s;
}

.btn-back:hover {
  background: #548dcd;
  color: #fff;
}
