.po-tooltip {
  position: relative;
  display: inline-block;
  background: url("../images/question.svg") no-repeat left;
  padding-right: 25px;
  height: 18px !important;
  top: 3px;
}

.po-tooltip.no-q {
  background: none;
}

.po-tooltip.icon {
  padding-right: 10px;
}

.po-tooltip {
  .po-tooltiptext {
    visibility: hidden;
    position: absolute;
    max-width: 400px;
    width: max-content;
    background-color: #555;
    color: #fff;
    padding: 10px;
    border-radius: 6px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .po-tooltip-position {
    top: -7px;
    left: 94%;
  }

  .po-tooltiptext::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
    top: 17px;
    right: 100%;
    margin-top: -5px;
    border-color: transparent #555 transparent transparent;
  }
}

.po-tooltip:hover .po-tooltiptext {
  visibility: visible;
  opacity: 1
}

.tooltip-inner {
  max-width: 400px !important;
  background-color: #555 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #555 !important;
}

.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #555 !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.text-after-question-icon {
  position: relative;
  right: 5px;
}
