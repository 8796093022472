.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #3180e2;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.5rem;
}

.react-datepicker__day-names,
.react-datepicker__week {
  font-size: 15px;
}

.react-datepicker__triangle {
  left: 10px !important;
}

.react-datepicker__day-names {
  background-color: #3180e2;
}

.react-datepicker__current-month {
  background-color: #3180e2;
  color: #ffffff;
}

.react-datepicker__day-name {
  color: #FFFFFF;
  font-weight: bold;
}

.react-datepicker__header {
  background-color: #3180e2;
}

.react-datepicker__navigation--next {
  border-left-color: #FFFFFF;
}

.react-datepicker__navigation--previous {
  border-right-color: #FFFFFF;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #3180e2 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
}
